
import { ApolloError } from 'apollo-client'
import { DocumentNode } from 'graphql'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import { mapBeteiligungToBeteiligungInput } from '@/application/GraphQLUtils'
import { deleteBeleg, uploadBeleg } from '@/application/RestApi'
import { rulesRequired } from '@/application/Validators'
import BeteiligungenSelectionComponent from '@/components/beteiligungen/BeteiligungenSelectionComponent.vue'
import BetraegeComponent from '@/components/beteiligungen/BetraegeComponent.vue'
import ErrorBanner, { ErrorData } from '@/components/ErrorBanner.vue'
import FilePreview from '@/components/files/FilePreview.vue'
import FileSelectComponent from '@/components/files/FileSelectComponent.vue'
import DateField from '@/components/forms/DateField.vue'
import EditDialogAction from '@/components/forms/EditDialogAction.vue'
import EditDialogCard from '@/components/forms/EditDialogCard.vue'
import {
  Account,
  AusgabeFieldsFragment,
  AusgabeQuery,
  AusgabeQueryVariables,
  AusgabeSpeichernDocument,
  AusgabeSpeichernMutation,
  AusgabeSpeichernMutationVariables,
  AusgabeStornierenDocument,
  AusgabeStornierenInput,
  AusgabeStornierenMutation,
  AusgabeStornierenMutationVariables,
  AusgabeTemplateDocument,
  AusgabeTemplateQuery,
  AusgabeTemplateQueryVariables,
  CreateBill,
  CreateBillDocument,
  CreateBillMutation,
  CreateBillMutationVariables,
  KontoFieldsFragment,
  KontosByTypeQuery,
  KontosByTypeQueryVariables,
  KontoTyp,
  Kunde,
  Tax,
  TransaktionStatus,
  UpsertAusgabeInput,
  useAccountsQuery,
  useAllKundenQuery,
  useAusgabeQuery,
  useKontosByTypeQuery,
  User,
  UsersQuery,
  useTaxesQuery,
  useUsersQuery,
  VerbuchenDocument,
  WiederholungsArt,
  ZurVerbuchungFreigebenDocument
} from '@/generated/graphql'

type AusgabeForm = AusgabeFieldsFragment

function mapAusgabeToAusgabeInput(ausgabe: AusgabeForm): UpsertAusgabeInput {
  return {
    id: ausgabe.id,
    version: ausgabe.version,
    datum: ausgabe.datum,
    bezeichnung: ausgabe.bezeichnung,
    notizen: ausgabe.notizen,
    betraege: ausgabe.betraege,
    kostentraeger: ausgabe.kostentraeger.map(mapBeteiligungToBeteiligungInput),
    bezahler: ausgabe.bezahler.map(mapBeteiligungToBeteiligungInput),
    belegId: ausgabe.belegId,
    wiederholung: ausgabe.wiederholung
  }
}

@Component({
  components: {
    ErrorBanner,
    DateField,
    EditDialogAction,
    EditDialogCard,
    BetraegeComponent,
    FileSelectComponent,
    FilePreview,
    BeteiligungenSelectionComponent
  },
  apollo: {
    ausgabe: useAusgabeQuery<AusgabeEdit>({
      variables(): AusgabeQueryVariables {
        if (this.ausgabeId) {
          return {
            id: this.ausgabeId
          }
        } else {
          throw new Error('no ausgabeId')
        }
      },
      skip(): boolean {
        return !this.ausgabeId // Only do this query, if we have an ID
      },
      update(data: AusgabeQuery): AusgabeFieldsFragment {
        this.ausgabeErfordertKeinenBeleg = data.ausgabe.belegId === null
        this.ausgabeMonatlich = data.ausgabe.wiederholung === WiederholungsArt.Monatlich
        return data.ausgabe
      }
    }),
    moeglicheKostentraeger: useKontosByTypeQuery<AusgabeEdit>({
      variables(): KontosByTypeQueryVariables {
        return {
          type: KontoTyp.Guthabenkonto
        }
      },
      update(data: KontosByTypeQuery): KontoFieldsFragment[] {
        return data.konten
      }
    }),
    moeglicheBezahler: useKontosByTypeQuery<AusgabeEdit>({
      variables(): KontosByTypeQueryVariables {
        return {
          type: KontoTyp.Spesenkonto
        }
      },
      update(data: KontosByTypeQuery): KontoFieldsFragment[] {
        return data.konten
      }
    }),
    kunden: useAllKundenQuery<AusgabeEdit>(),
    users: useUsersQuery<AusgabeEdit>({
      skip(): boolean {
        return !this.$store.state.benutzer
      },
      update(data: UsersQuery): User[] {
        this.selectedUser = data.users.find(x => x.id === '2') ?? null
        return data.users
      }
    }),
    accounts: useAccountsQuery<AusgabeEdit>({
      skip(): boolean {
        return !this.$store.state.benutzer
      }
    }),
    taxes: useTaxesQuery({
      skip(): boolean {
        return !this.$store.state.benutzer
      }
    })
  }
})
export default class AusgabeEdit extends Vue {

  users: User[] = []
  accounts: Account[] = []
  taxes: Tax[] = []
  kunden: Kunde[] = []

  selectedUser: User | null = null
  selectedAccount: Account | null = null
  selectedTax: Tax | null = null
  selectedKunde: Kunde | null = null

  @Prop({
    required: false,
    default: null
  })
  readonly ausgabeId!: string | null

  @Prop({
    required: true,
    default: false
  })
  readonly isShow!: boolean

  moeglicheKostentraeger: KontoFieldsFragment[] = []
  moeglicheBezahler: KontoFieldsFragment[] = []

  // Form Data
  ausgabe: AusgabeForm | null = null

  ausgabeArten = ['Pauschalspesen', 'Miete', 'Fahrspesen Privatauto (km)']
  ausgabeErfordertKeinenBeleg = false
  ausgabeMonatlich = false

  image: File | null = null
  imageContent: string | null = null

  loading = 0

  // Form Validation
  formValid = false
  formBuchhalterValid = false
  readonly rulesRequired: InputValidationRules = rulesRequired

  errorData: ErrorData | null = null

  // noinspection JSUnusedGlobalSymbols
  mounted(): void {
    if (!this.ausgabeId) {
      this.ausgabeTemplate()
    }
  }

  @Watch('isShow', { immediate: true })
  isShowWatcher(): void {
    if (!this.ausgabeId) {
      this.ausgabe = null
      this.ausgabeTemplate()
    }
  }

  get isBuchhalter(): boolean {
    return this.$store.state.benutzer.isBuchhalter
  }

  get isCreateMode(): boolean {
    return !this.ausgabeId
  }

  get isSpesen(): boolean {
    const empfaenger = this.ausgabe?.bezahler || []
    if (empfaenger.length === 1) {
      return !empfaenger[0].kontoId.includes('sstzahlung')
    } else {
      return empfaenger.length > 0
    }
  }

  get isSpeichernEnabled(): boolean {
    if (this.ausgabe && this.ausgabe.status !== TransaktionStatus.Storniert) {
      if (this.ausgabe.status === TransaktionStatus.Ok) {
        return this.$store.state.benutzer.isBuchhalter
      }

      return true
    }

    return false
  }

  get isFreigebenEnabled(): boolean {
    return this.ausgabe?.status === TransaktionStatus.InBearbeitung
  }

  get isAlsVerbuchtMarkierenEnabled(): boolean {
    return this.ausgabe?.status === TransaktionStatus.ZuVerbuchen && this.$store.state.benutzer.isBuchhalter
  }

  get isStornierenEnabled(): boolean {
    if (this.ausgabe && this.ausgabe.version && this.ausgabe.status !== TransaktionStatus.Storniert) {
      if (this.ausgabe.status === TransaktionStatus.Ok) {
        return this.$store.state.benutzer.isBuchhalter
      }

      return true
    }

    return false
  }

  get showPauschalbetragHint(): boolean {
    if (this.ausgabe && this.ausgabe.betraege && this.ausgabe.betraege.betragBrutto) {
      const betragNumber = Number.parseInt(this.ausgabe.betraege.betragBrutto)
      return this.isSpesen && betragNumber <= 50
    }
    return false
  }

  get isDisabled(): boolean {
    return !this.$store.state.benutzer.isBuchhalter && this.ausgabe?.status !== TransaktionStatus.InBearbeitung
  }

  async ausgabeTemplate(): Promise<void> {
    return await this.ausgabeTemplateMitBeleg()
  }

  async ausgabeTemplateMitBeleg(image?: File): Promise<void> {
    try {
      this.loading += 1

      const variables: AusgabeTemplateQueryVariables = {}
      if (image) {
        if (this.ausgabe && this.ausgabe.belegId) {
          console.log('delete beleg', this.ausgabe.belegId)
          await deleteBeleg(this.ausgabe.belegId)
        }
        variables.belegId = await uploadBeleg(image)
        console.log('upload beleg', variables.belegId)
      }
      const res = await this.$apollo.query<AusgabeTemplateQuery, AusgabeTemplateQueryVariables>({
        query: AusgabeTemplateDocument,
        variables
      })
      // set betraege if those could be read from Beleg
      if (image && this.ausgabe && this.ausgabe.betraege && res.data && res.data.ausgabeTemplate.betraege) {
        const b = res.data.ausgabeTemplate.betraege
        this.ausgabe.betraege.betragBrutto = parseFloat(b.betragBrutto) > 0 ? b.betragBrutto : this.ausgabe.betraege.betragBrutto
        this.ausgabe.betraege.betragNetto = parseFloat(b.betragBrutto) > 0 ? b.betragNetto : this.ausgabe.betraege.betragNetto
        this.ausgabe.betraege.betragMwst = parseFloat(b.betragBrutto) > 0 ? b.betragMwst : this.ausgabe.betraege.betragMwst
        this.ausgabe.belegId = variables.belegId
      } else {
        this.ausgabe = res.data?.ausgabeTemplate
      }
    } catch (e) {
      this.errorData = {
        message: '' + e
      }
    } finally {
      this.loading -= 1
    }
  }

  speichern(): void {
    setTimeout(() => this.save(AusgabeSpeichernDocument), 100)
  }

  freigeben(): void {
    setTimeout(() => this.save(ZurVerbuchungFreigebenDocument), 100)
  }

  alsVerbuchtMarkieren(): void {
    setTimeout(() => this.save(VerbuchenDocument), 100)
    setTimeout(async () => {
      if (this.ausgabe) {
        const bill: CreateBill = {
          title: this.ausgabe.bezeichnung,
          contactPartnerId: this.selectedUser?.id,
          supplierId: this.selectedKunde?.id,
          amountCalc: this.ausgabe?.betraege?.betragNetto,
          address: {
            lastnameCompany: this.selectedKunde?.bezeichnung,
            addressLine: this.selectedKunde?.strasse,
            postcode: this.selectedKunde?.plz,
            city: this.selectedKunde?.stadt,
            type: this.selectedKunde?.type
          },
          lineItems: [
            {
              position: '0',
              amount: this.ausgabe?.betraege?.betragNetto,
              taxId: this.selectedTax?.id,
              bookingAccountId: this.selectedAccount?.id
            }
          ],
          attachmentIds: [
            this.ausgabe && this.ausgabe.belegId ? this.ausgabe.belegId : ''
          ]
        }
        await this.makeApolloRequest(() => {
          return this.$apollo.mutate<CreateBillMutation, CreateBillMutationVariables>({
            mutation: CreateBillDocument,
            variables: {
              input: bill
            }
          })
        })
      }
    })
  }

  stornieren(): void {
    if (this.ausgabe?.version && this.ausgabe.id) {
      const input: AusgabeStornierenInput = {
        id: this.ausgabe.id,
        version: this.ausgabe.version
      }
      setTimeout(() => {
        this.makeApolloRequest(() => {
          return this.$apollo.mutate<AusgabeStornierenMutation, AusgabeStornierenMutationVariables>({
            mutation: AusgabeStornierenDocument,
            variables: { input }
          })
        })
      }, 100)
    }
  }

  private async save(mutation: DocumentNode): Promise<void> {
    if (this.ausgabe) {
      const input: UpsertAusgabeInput = mapAusgabeToAusgabeInput(this.ausgabe)
      if (this.ausgabeErfordertKeinenBeleg && input.belegId) {
        await deleteBeleg(input.belegId)
        delete input.belegId
      }
      await this.makeApolloRequest(() => {
        return this.$apollo.mutate<AusgabeSpeichernMutation, AusgabeSpeichernMutationVariables>({
          mutation,
          variables: { input }
        })
      })

      this.$emit('completed')
    } else {
      throw new Error('no ausgabe')
    }

  }

  private async makeApolloRequest(callback: () => Promise<unknown>): Promise<void> {
    try {
      this.loading += 1

      await callback()

      this.$emit('completed')
    } catch (e) {
      if (e instanceof ApolloError) {
        const graphQLError = e.graphQLErrors[0]
        this.errorData = {
          code: graphQLError?.extensions?.code,
          message: graphQLError?.message
        }
      } else {
        throw e
      }
    } finally {
      this.loading -= 1
    }
  }

  cancel(): void {
    this.$emit('cancelled')
  }

  refresh(): void {
    this.errorData = null
    this.$apollo.queries.ausgabe.refetch()
  }

  fileSelected(beleg: File): void {
    this.image = beleg
    if (this.ausgabe) {
      this.ausgabe.belegContentType = beleg?.type
    }
    this.ausgabeTemplateMitBeleg(this.image)
  }

  get hasBeleg(): boolean {
    return this.image !== null || (!!this.ausgabe && this.ausgabe.belegId !== null)
  }

  @Watch('ausgabeMonatlich')
  wiederholung(): void {
    if (this.ausgabe) {
      this.ausgabeMonatlich ? this.ausgabe.wiederholung = WiederholungsArt.Monatlich : this.ausgabe.wiederholung = WiederholungsArt.Einmalig
    }
  }

  get ausgabeErfordertBeleg(): boolean {
    return !this.ausgabeErfordertKeinenBeleg
  }

  isRecurrent(parentId: string): boolean {
    return !!parentId
  }

  get share(): string | undefined {
    return this.ausgabeId ? `/ausgaben/${this.ausgabeId}` : undefined
  }
}
