
import { Component, Vue } from 'vue-property-decorator'

/**
 * The required JS file is added directly to index.html
 */
@Component
export default class GoogleLogin extends Vue {

  // noinspection JSUnusedGlobalSymbols
  mounted() {
    window.google.accounts.id.initialize({
      client_id: "193251729200-ig5vfj0f39o902qgngge51e1p7mnad5f.apps.googleusercontent.com",
      callback: this.loggedin,
      auto_select: true
    });
    window.google.accounts.id.renderButton(
        document.getElementById("sapp-google-login"),
        { theme: "outline", size: "large" }  // customization attributes
    );
    window.google.accounts.id.prompt(); // also display the One Tap dialog
  }

  loggedin(data: AuthCallback) {
    this.$store.commit('updateAccessToken', data.credential)
  }

}

type AuthCallback = {
  credential: string
}

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

window.google = window.google || {};
