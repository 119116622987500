import gql from 'graphql-tag';
import { createMutationFunction, createSmartQueryOptionsFunction, createSmartSubscriptionOptionsFunction } from 'vue-apollo-smart-ops';
import { ApolloError } from 'apollo-client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: string;
  Float: number;
  BigDecimal: string;
  Long: string;
};

export type Account = {
  __typename?: 'Account';
  accountNo?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['Int']>;
  fibuAccountGroupId?: Maybe<Scalars['Long']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['Long']>;
  uuid?: Maybe<Scalars['String']>;
};

export type Arbeitsleistung = {
  __typename?: 'Arbeitsleistung';
  anzahlStunden: Scalars['BigDecimal'];
  auftragsPositionId?: Maybe<Scalars['String']>;
  betrag: Scalars['BigDecimal'];
  bezeichnung?: Maybe<Scalars['String']>;
  stundensatz: Scalars['BigDecimal'];
};

export type ArbeitsleistungInput = {
  anzahlStunden: Scalars['BigDecimal'];
  auftragsPositionId?: InputMaybe<Scalars['String']>;
  bezeichnung?: InputMaybe<Scalars['String']>;
  stundensatz: Scalars['BigDecimal'];
};

export type Auftrag = LabeledIdentifiable & {
  __typename?: 'Auftrag';
  bezeichnung: Scalars['String'];
  budget: Scalars['BigDecimal'];
  id: Scalars['ID'];
  positionen: Array<AuftragsPosition>;
};

export type AuftragsPosition = LabeledIdentifiable & {
  __typename?: 'AuftragsPosition';
  bezeichnung: Scalars['String'];
  id: Scalars['ID'];
  preisProEinheit: Scalars['BigDecimal'];
};

export type Ausgabe = Transaktion & {
  __typename?: 'Ausgabe';
  belegContentType?: Maybe<Scalars['String']>;
  belegId?: Maybe<Scalars['String']>;
  belegUrl?: Maybe<Scalars['String']>;
  betraege: BruttoNettoMwstBetrag;
  bezahler: Array<Beteiligung>;
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  id: Scalars['ID'];
  kostentraeger: Array<Beteiligung>;
  notizen: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  status: TransaktionStatus;
  version?: Maybe<Scalars['Int']>;
  wiederholung: WiederholungsArt;
};

export type AusgabePage = {
  __typename?: 'AusgabePage';
  content: Array<Ausgabe>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type AusgabeStornierenInput = {
  id: Scalars['ID'];
  version: Scalars['Int'];
};

export type Auszahlung = Transaktion & {
  __typename?: 'Auszahlung';
  betrag: Scalars['BigDecimal'];
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  id: Scalars['ID'];
  status: TransaktionStatus;
  typ: AuszahlungsTyp;
  version: Scalars['Int'];
};

export type AuszahlungInput = {
  betrag: Scalars['BigDecimal'];
  datum?: InputMaybe<Scalars['String']>;
  empfaenger: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  typ: AuszahlungsTyp;
  version?: InputMaybe<Scalars['Int']>;
};

export type AuszahlungStornierenInput = {
  id?: InputMaybe<Scalars['ID']>;
  version: Scalars['Int'];
};

export type AuszahlungsDaten = {
  __typename?: 'AuszahlungsDaten';
  ausbezahlt: Scalars['BigDecimal'];
  betrag: Scalars['BigDecimal'];
  verbleibend: Scalars['BigDecimal'];
};

export enum AuszahlungsTyp {
  Bonus = 'BONUS',
  Spesen = 'SPESEN'
}

export type Bankverbindung = {
  __typename?: 'Bankverbindung';
  bic: Scalars['String'];
  iban: Scalars['String'];
  name: Scalars['String'];
};

export type BankverbindungInput = {
  bic: Scalars['String'];
  iban: Scalars['String'];
  name: Scalars['String'];
};

export type Benutzer = Identifiable & {
  __typename?: 'Benutzer';
  adresse?: Maybe<Scalars['String']>;
  ahv?: Maybe<Scalars['String']>;
  arbeitspensum: Scalars['Int'];
  aufMandatSuche?: Maybe<Scalars['Boolean']>;
  bankverbindung?: Maybe<Bankverbindung>;
  beschaeftigungsgrad?: Maybe<Scalars['Int']>;
  bvg?: Maybe<Bvg>;
  bvgLastYear?: Maybe<Bvg>;
  email: Scalars['String'];
  geburtstag?: Maybe<Scalars['String']>;
  geschlecht?: Maybe<Geschlecht>;
  hasGA: Scalars['Boolean'];
  hasVerpflegung: Scalars['Boolean'];
  id: Scalars['ID'];
  isBuchhalter: Scalars['Boolean'];
  isLohnAutomatischGenerieren: Scalars['Boolean'];
  isMember: Scalars['Boolean'];
  kinderzulagen?: Maybe<Scalars['BigDecimal']>;
  ktg?: Maybe<Ktg>;
  linkedin?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  mobile?: Maybe<Scalars['String']>;
  monatslohn?: Maybe<Scalars['BigDecimal']>;
  monatslohnAusnahmen?: Maybe<Array<Maybe<Lohnausnahmen>>>;
  name: Scalars['String'];
  pauschalspesen?: Maybe<Scalars['BigDecimal']>;
  photoUrl?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  unbezahlteMonate: Array<Scalars['String']>;
  version: Scalars['Int'];
  webpage?: Maybe<Scalars['String']>;
};

export type Beteiligung = {
  __typename?: 'Beteiligung';
  anteile?: Maybe<Scalars['Int']>;
  betrag: Scalars['BigDecimal'];
  fixbetrag: Scalars['Boolean'];
  konto: Konto;
  kontoId: Scalars['ID'];
};

export type BeteiligungInput = {
  anteile?: InputMaybe<Scalars['Int']>;
  betrag: Scalars['BigDecimal'];
  kontoId: Scalars['ID'];
};

export type BeteiligungenBerechnenBaselineInput = {
  beteiligungen: Array<BeteiligungInput>;
  totalBetrag: Scalars['BigDecimal'];
};

export type BruttoNettoMwstBetrag = {
  __typename?: 'BruttoNettoMwstBetrag';
  betragBrutto: Scalars['BigDecimal'];
  betragMwst: Scalars['BigDecimal'];
  betragNetto: Scalars['BigDecimal'];
};

export type BruttoNettoMwstBetragInput = {
  betragBrutto: Scalars['BigDecimal'];
  betragMwst: Scalars['BigDecimal'];
  betragNetto: Scalars['BigDecimal'];
};

export type Buchung = {
  __typename?: 'Buchung';
  betrag: Scalars['BigDecimal'];
  bezeichnung: Scalars['String'];
  id: Scalars['ID'];
  konto: Konto;
  kontoId: Scalars['ID'];
  opposingKonten: Array<Konto>;
  opposingKontoIds: Array<Scalars['String']>;
  sourceTransaktion?: Maybe<Transaktion>;
  status: BuchungStatus;
  valuta: Scalars['String'];
  version: Scalars['Int'];
};

export type BuchungPage = {
  __typename?: 'BuchungPage';
  content: Array<Buchung>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export enum BuchungStatus {
  Effektiv = 'EFFEKTIV'
}

export type Bvg = {
  __typename?: 'Bvg';
  arbeitgeberAnteil: Scalars['BigDecimal'];
  arbeitnehmerAnteil: Scalars['BigDecimal'];
};

export type BvgInput = {
  arbeitgeberAnteil: Scalars['BigDecimal'];
  arbeitnehmerAnteil: Scalars['BigDecimal'];
};

export type CreateBill = {
  address?: InputMaybe<CreateBillAddress>;
  amountCalc?: InputMaybe<Scalars['BigDecimal']>;
  amountMan?: InputMaybe<Scalars['BigDecimal']>;
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  baseCurrencyAmount?: InputMaybe<Scalars['BigDecimal']>;
  billDate?: InputMaybe<Scalars['String']>;
  contactPartnerId?: InputMaybe<Scalars['Long']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<Array<InputMaybe<CreateBillDiscount>>>;
  dueDate?: InputMaybe<Scalars['String']>;
  exchangeRate?: InputMaybe<Scalars['BigDecimal']>;
  itemNet?: InputMaybe<Scalars['Boolean']>;
  lineItems?: InputMaybe<Array<InputMaybe<CreateBillLineItem>>>;
  manualAmount?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<CreateBillPayment>;
  purchaseOrderId?: InputMaybe<Scalars['Long']>;
  qrBillInformation?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Long']>;
  title?: InputMaybe<Scalars['String']>;
  vendorRef?: InputMaybe<Scalars['String']>;
};

export type CreateBillAddress = {
  addressLine?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contactAddressId?: InputMaybe<Scalars['Long']>;
  countryCode?: InputMaybe<Scalars['String']>;
  firstnameSuffix?: InputMaybe<Scalars['String']>;
  lastnameCompany?: InputMaybe<Scalars['String']>;
  mainContactId?: InputMaybe<Scalars['Long']>;
  postcode?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateBillDiscount = {
  amount?: InputMaybe<Scalars['BigDecimal']>;
  position?: InputMaybe<Scalars['Long']>;
};

export type CreateBillLineItem = {
  amount?: InputMaybe<Scalars['BigDecimal']>;
  bookingAccountId?: InputMaybe<Scalars['Long']>;
  position?: InputMaybe<Scalars['Long']>;
  taxId?: InputMaybe<Scalars['Long']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateBillPayment = {
  name?: InputMaybe<Scalars['String']>;
};

export type CreateKundeInput = {
  bezeichnung: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  plz?: InputMaybe<Scalars['String']>;
  stadt?: InputMaybe<Scalars['String']>;
  strasse?: InputMaybe<Scalars['String']>;
};

export type Erfolgsrechnung = {
  __typename?: 'Erfolgsrechnung';
  kostenAusgaben: Scalars['BigDecimal'];
  kostenNettolohn: Scalars['BigDecimal'];
  kostenTotalabzuegeAg: Scalars['BigDecimal'];
  kostenTotalabzuegeAn: Scalars['BigDecimal'];
  kostenUebertraege: Scalars['BigDecimal'];
  kostenUmsatzabgabe: Scalars['BigDecimal'];
  memberId: Scalars['ID'];
  memberName: Scalars['String'];
  spesenTotal: Scalars['BigDecimal'];
  totalKosten: Scalars['BigDecimal'];
  totalSaldo: Scalars['BigDecimal'];
  totalUmsatz: Scalars['BigDecimal'];
  umsatzExtern: Scalars['BigDecimal'];
  umsatzIntern: Scalars['BigDecimal'];
  umsatzUebertraege: Scalars['BigDecimal'];
};

export type ErfolgsrechnungDetail = {
  __typename?: 'ErfolgsrechnungDetail';
  betrag: Scalars['BigDecimal'];
  bezeichnung: Scalars['String'];
  valuta: Scalars['String'];
};

export type ErfolgsrechnungDetailEntry = {
  __typename?: 'ErfolgsrechnungDetailEntry';
  entries: Array<Maybe<ErfolgsrechnungDetail>>;
  key: Scalars['String'];
};

export type ErfolgsrechnungDetailSheet = {
  __typename?: 'ErfolgsrechnungDetailSheet';
  data: Array<Maybe<ErfolgsrechnungDetailEntry>>;
};

export enum Geschlecht {
  Andere = 'ANDERE',
  Maennlich = 'MAENNLICH',
  Weiblich = 'WEIBLICH'
}

export type Identifiable = {
  id: Scalars['ID'];
};

export type Kontakt = LabeledIdentifiable & {
  __typename?: 'Kontakt';
  bezeichnung: Scalars['String'];
  id: Scalars['ID'];
};

export type Konto = {
  __typename?: 'Konto';
  besitzer: KontoBesitzer;
  bezeichnung: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kontostand: Kontostand;
  letzteBuchungen: Array<Buchung>;
  typ: KontoTyp;
  version: Scalars['Int'];
};


export type KontoKontostandArgs = {
  year: Scalars['Int'];
};


export type KontoLetzteBuchungenArgs = {
  year: Scalars['Int'];
};

export type KontoBesitzer = Member | Topf;

export enum KontoTyp {
  Guthabenkonto = 'GUTHABENKONTO',
  Spesenkonto = 'SPESENKONTO'
}

export type Kontostand = {
  __typename?: 'Kontostand';
  budget: Scalars['BigDecimal'];
  geplant: Scalars['BigDecimal'];
  verbraucht: Scalars['BigDecimal'];
  verfuegbar: Scalars['BigDecimal'];
  verfuegbarProzent: Scalars['BigDecimal'];
};

export type Ktg = {
  __typename?: 'Ktg';
  tage: Scalars['Int'];
};

export type KtgInput = {
  tage: Scalars['Int'];
};

export type Kunde = LabeledIdentifiable & {
  __typename?: 'Kunde';
  auftraege: Array<Auftrag>;
  bezeichnung: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kontakte: Array<Kontakt>;
  plz?: Maybe<Scalars['String']>;
  stadt?: Maybe<Scalars['String']>;
  strasse?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Kundendaten = {
  __typename?: 'Kundendaten';
  auftrag?: Maybe<Auftrag>;
  auftragId?: Maybe<Scalars['String']>;
  kontakt?: Maybe<Kontakt>;
  kontaktId?: Maybe<Scalars['String']>;
  kunde?: Maybe<Kunde>;
  kundeId?: Maybe<Scalars['String']>;
};

export type KundendatenInput = {
  auftragId?: InputMaybe<Scalars['String']>;
  kontaktId?: InputMaybe<Scalars['String']>;
  kundeId?: InputMaybe<Scalars['String']>;
};

export type LabeledIdentifiable = {
  bezeichnung: Scalars['String'];
  id: Scalars['ID'];
};

export type Leistung = Transaktion & {
  __typename?: 'Leistung';
  arbeitsleistungen: Array<Arbeitsleistung>;
  belegContentType?: Maybe<Scalars['String']>;
  belegId?: Maybe<Scalars['String']>;
  belegUrl?: Maybe<Scalars['String']>;
  betragTotal: Scalars['BigDecimal'];
  bexioStatus: LeistungsBexioStatus;
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  erbringer: Member;
  erbringerId: Scalars['String'];
  id: Scalars['ID'];
  kundendaten?: Maybe<Kundendaten>;
  leistungsArt?: Maybe<LeistungsArt>;
  notizen: Scalars['String'];
  rechnungId?: Maybe<Scalars['String']>;
  rechnungUrl?: Maybe<Scalars['String']>;
  status: TransaktionStatus;
  topf?: Maybe<Topf>;
  topfId?: Maybe<Scalars['String']>;
  verpflegung: Verpflegung;
  version: Scalars['Int'];
};

export type LeistungInput = {
  arbeitsleistungen: Array<ArbeitsleistungInput>;
  belegId?: InputMaybe<Scalars['String']>;
  bezeichnung?: InputMaybe<Scalars['String']>;
  datum: Scalars['String'];
  erbringerId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  kundendaten?: InputMaybe<KundendatenInput>;
  leistungsArt?: InputMaybe<LeistungsArt>;
  notizen: Scalars['String'];
  topfId?: InputMaybe<Scalars['String']>;
  verpflegung: VerpflegungInput;
  version?: InputMaybe<Scalars['Int']>;
};

export type LeistungPage = {
  __typename?: 'LeistungPage';
  content: Array<Leistung>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type LeistungStornierenInput = {
  id?: InputMaybe<Scalars['ID']>;
  version: Scalars['Int'];
};

export enum LeistungsArt {
  Extern = 'EXTERN',
  Intern = 'INTERN'
}

export enum LeistungsBexioStatus {
  Bezahlt = 'BEZAHLT',
  Entwurf = 'ENTWURF',
  Offen = 'OFFEN',
  Storniert = 'STORNIERT',
  Teilweise = 'TEILWEISE',
  Unbekannt = 'UNBEKANNT',
  Unbezahlt = 'UNBEZAHLT'
}

export type Lohn = Identifiable & {
  __typename?: 'Lohn';
  ahvIvEoAg: Scalars['BigDecimal'];
  ahvIvEoAgFormel: Scalars['String'];
  ahvIvEoAn: Scalars['BigDecimal'];
  ahvIvEoAnFormel: Scalars['String'];
  ahvVerwaltungAg: Scalars['BigDecimal'];
  ahvVerwaltungAgFormel: Scalars['String'];
  alv2Ag: Scalars['BigDecimal'];
  alv2AgFormel: Scalars['String'];
  alv2An: Scalars['BigDecimal'];
  alv2AnFormel: Scalars['String'];
  alvAg: Scalars['BigDecimal'];
  alvAgFormel: Scalars['String'];
  alvAn: Scalars['BigDecimal'];
  alvAnFormel: Scalars['String'];
  auszahlbar: Scalars['BigDecimal'];
  brutto: Scalars['BigDecimal'];
  buAg: Scalars['BigDecimal'];
  buAgFormel: Scalars['String'];
  bvgAg: Scalars['BigDecimal'];
  bvgAn: Scalars['BigDecimal'];
  fakAg: Scalars['BigDecimal'];
  fakAgFormel: Scalars['String'];
  id: Scalars['ID'];
  kinderzulagen: Scalars['BigDecimal'];
  ktgAg: Scalars['BigDecimal'];
  ktgAn: Scalars['BigDecimal'];
  ktgTyp: Scalars['String'];
  memberId: Scalars['String'];
  nbuAn: Scalars['BigDecimal'];
  nbuAnFormel: Scalars['String'];
  netto: Scalars['BigDecimal'];
  nettoUngerundet: Scalars['BigDecimal'];
  totalAbzuegeAg: Scalars['BigDecimal'];
  totalAbzuegeAn: Scalars['BigDecimal'];
  yearMonth: Scalars['String'];
};

export type Lohnausnahmen = {
  __typename?: 'Lohnausnahmen';
  monatslohn: Scalars['BigDecimal'];
  yearMonth: Scalars['String'];
};

export type LohnausnahmenInput = {
  monatslohn: Scalars['BigDecimal'];
  yearMonth: Scalars['String'];
};

export type Lohnausweis = {
  __typename?: 'Lohnausweis';
  brutto: Scalars['BigDecimal'];
  bvgAg: Scalars['BigDecimal'];
  bvgAn: Scalars['BigDecimal'];
  kinderzulagen: Scalars['BigDecimal'];
  netto: Scalars['BigDecimal'];
  pauschalspesen: Scalars['BigDecimal'];
  sozialAbgabenAg: Scalars['BigDecimal'];
  sozialAbgabenAn: Scalars['BigDecimal'];
  spesen: Scalars['BigDecimal'];
};

export type Lohndaten = {
  __typename?: 'Lohndaten';
  bonus: Scalars['BigDecimal'];
  bonusNetto: Scalars['BigDecimal'];
  bruttoloehne: Array<Scalars['BigDecimal']>;
  id: Scalars['ID'];
  jahr: Scalars['Int'];
  kinderzulagen: Scalars['BigDecimal'];
  ktg: Scalars['BigDecimal'];
  memberId: Scalars['String'];
  sozialversicherungenAG: Scalars['BigDecimal'];
  version: Scalars['Int'];
};

export type Member = Identifiable & {
  __typename?: 'Member';
  benutzer: Benutzer;
  bonus: AuszahlungsDaten;
  bonusAuszahlungen: Array<Auszahlung>;
  guthabenkonto: Konto;
  id: Scalars['ID'];
  loehne: Array<Lohn>;
  lohnausweis: Lohnausweis;
  lohndaten: Lohndaten;
  name: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  spesen: AuszahlungsDaten;
  spesenAuszahlungen: Array<Auszahlung>;
  spesenkonto: Konto;
  version: Scalars['Int'];
};


export type MemberBenutzerArgs = {
  year: Scalars['Int'];
};


export type MemberBonusArgs = {
  year: Scalars['Int'];
};


export type MemberBonusAuszahlungenArgs = {
  year: Scalars['Int'];
};


export type MemberLoehneArgs = {
  year: Scalars['Int'];
};


export type MemberLohnausweisArgs = {
  year: Scalars['Int'];
};


export type MemberLohndatenArgs = {
  year: Scalars['Int'];
};


export type MemberSpesenArgs = {
  year: Scalars['Int'];
};


export type MemberSpesenAuszahlungenArgs = {
  year: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  ausgabeSpeichern: Ausgabe;
  ausgabeStornieren: Ausgabe;
  ausgabeVerbuchen: Ausgabe;
  ausgabeZurVerbuchungFreigeben: Ausgabe;
  auszahlungStornieren: Auszahlung;
  createBill: Scalars['ID'];
  createKunde: Scalars['ID'];
  createTopf: Topf;
  createUebertrag: Uebertrag;
  deleteLoehne: Array<Scalars['ID']>;
  deleteLohn: Scalars['ID'];
  deleteTopf: Scalars['Long'];
  leistungStornieren: Leistung;
  leistungVerbuchen: Leistung;
  leistungZurVerbuchungFreigeben: Leistung;
  storniereUebertrag: Uebertrag;
  topfOeffnen: Topf;
  topfSchliessen: Topf;
  topfStimmeAbgeben: Topf;
  updateBenutzer: Benutzer;
  updateKunde: Scalars['ID'];
  updateLohndaten: Array<Lohndaten>;
  updateTopf: Topf;
  updateUebertrag: Uebertrag;
  upsertAuszahlung: Auszahlung;
  upsertLeistung: Leistung;
};


export type MutationAusgabeSpeichernArgs = {
  input: UpsertAusgabeInput;
};


export type MutationAusgabeStornierenArgs = {
  input: AusgabeStornierenInput;
};


export type MutationAusgabeVerbuchenArgs = {
  input: UpsertAusgabeInput;
};


export type MutationAusgabeZurVerbuchungFreigebenArgs = {
  input: UpsertAusgabeInput;
};


export type MutationAuszahlungStornierenArgs = {
  input?: InputMaybe<AuszahlungStornierenInput>;
};


export type MutationCreateBillArgs = {
  input?: InputMaybe<CreateBill>;
};


export type MutationCreateKundeArgs = {
  input?: InputMaybe<CreateKundeInput>;
};


export type MutationCreateTopfArgs = {
  input?: InputMaybe<TopfCreateInput>;
};


export type MutationCreateUebertragArgs = {
  input?: InputMaybe<UebertragCreateInput>;
};


export type MutationDeleteLoehneArgs = {
  benutzerId?: InputMaybe<Scalars['String']>;
  yearMonth?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteLohnArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTopfArgs = {
  id: Scalars['ID'];
};


export type MutationLeistungStornierenArgs = {
  input?: InputMaybe<LeistungStornierenInput>;
};


export type MutationLeistungVerbuchenArgs = {
  input?: InputMaybe<LeistungInput>;
};


export type MutationLeistungZurVerbuchungFreigebenArgs = {
  updateInfo?: InputMaybe<LeistungInput>;
};


export type MutationStorniereUebertragArgs = {
  id: Scalars['ID'];
};


export type MutationTopfOeffnenArgs = {
  id: Scalars['ID'];
};


export type MutationTopfSchliessenArgs = {
  id: Scalars['ID'];
};


export type MutationTopfStimmeAbgebenArgs = {
  id: Scalars['ID'];
  stimme?: InputMaybe<TopfStimmeInput>;
};


export type MutationUpdateBenutzerArgs = {
  input?: InputMaybe<UpdateBenutzerInput>;
};


export type MutationUpdateKundeArgs = {
  input?: InputMaybe<UpdateKundeInput>;
};


export type MutationUpdateLohndatenArgs = {
  input: Array<UpdateLohndatenInput>;
};


export type MutationUpdateTopfArgs = {
  input?: InputMaybe<TopfUpdateInput>;
};


export type MutationUpdateUebertragArgs = {
  input?: InputMaybe<UebertragUpdateInput>;
};


export type MutationUpsertAuszahlungArgs = {
  updateInfo?: InputMaybe<AuszahlungInput>;
};


export type MutationUpsertLeistungArgs = {
  updateInfo?: InputMaybe<LeistungInput>;
};

export type Query = {
  __typename?: 'Query';
  accounts: Array<Account>;
  ausgabe: Ausgabe;
  ausgabeTemplate: Ausgabe;
  ausgabenByKontoIdAndStatusPaged: AusgabePage;
  auszahlung: Auszahlung;
  benutzer: Benutzer;
  bonusAuszahlungenByEmpfaengerAndStatusIsIn: Array<Maybe<Auszahlung>>;
  bonusByMemberIdAndYear: Scalars['BigDecimal'];
  buchungenPaged: BuchungPage;
  erfolgsrechnungByMemberIdAndYear: Erfolgsrechnung;
  erfolgsrechnungDetailByMemberIdAndYear: ErfolgsrechnungDetailSheet;
  erfolgsrechnungen: Array<Erfolgsrechnung>;
  konten: Array<Konto>;
  konto: Konto;
  kontosByTypeAndYear: Array<Konto>;
  kunde: Kunde;
  kunden: Array<Kunde>;
  kundenauftraege: Array<Auftrag>;
  kundenkontakte: Array<Kontakt>;
  leistung: Leistung;
  leistungenByMemberIdAndStatusAndLeistungsArtPaged: LeistungPage;
  leistungenPaged: LeistungPage;
  lohn: Lohn;
  me: Benutzer;
  member: Member;
  members: Array<Member>;
  spesenAuszahlungenByEmpfaengerAndStatusIsIn: Array<Maybe<Auszahlung>>;
  taxes: Array<Tax>;
  toepfe: Array<Topf>;
  topf: Topf;
  uebertraegeByKontoIdAndStatusPaged: UebertragPage;
  uebertraegePaged: UebertragPage;
  uebertrag?: Maybe<Uebertrag>;
  updateBeteiligte: Array<Beteiligung>;
  updateBeteiligungAnteile: Array<Beteiligung>;
  updateBeteiligungBetrag: Array<Beteiligung>;
  updateBeteiligungFixbetragStatus: Array<Beteiligung>;
  updateTotalBetrag: Array<Beteiligung>;
  user: User;
  users: Array<User>;
  verbuchteLeistungen?: Maybe<Array<VerbuchteLeistung>>;
  withBetragBrutto: BruttoNettoMwstBetrag;
  withBetragMwst: BruttoNettoMwstBetrag;
  withBetragNetto: BruttoNettoMwstBetrag;
};


export type QueryAusgabeArgs = {
  id: Scalars['ID'];
};


export type QueryAusgabeTemplateArgs = {
  belegId?: InputMaybe<Scalars['ID']>;
};


export type QueryAusgabenByKontoIdAndStatusPagedArgs = {
  bezahler: Scalars['ID'];
  bezahlerExakt?: InputMaybe<Scalars['Boolean']>;
  kostentraeger: Scalars['ID'];
  kostentraegerExakt?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  verpflegungsSpesenAusLeistungenEinblenden?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryAuszahlungArgs = {
  id: Scalars['ID'];
};


export type QueryBenutzerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBonusAuszahlungenByEmpfaengerAndStatusIsInArgs = {
  empfaenger: Scalars['ID'];
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBonusByMemberIdAndYearArgs = {
  memberId: Scalars['ID'];
  year: Scalars['Int'];
};


export type QueryBuchungenPagedArgs = {
  kontoId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryErfolgsrechnungByMemberIdAndYearArgs = {
  memberId: Scalars['ID'];
  year: Scalars['Int'];
};


export type QueryErfolgsrechnungDetailByMemberIdAndYearArgs = {
  memberId: Scalars['ID'];
  year: Scalars['Int'];
};


export type QueryErfolgsrechnungenArgs = {
  year: Scalars['Int'];
};


export type QueryKontenArgs = {
  typ?: InputMaybe<KontoTyp>;
};


export type QueryKontoArgs = {
  id: Scalars['ID'];
};


export type QueryKontosByTypeAndYearArgs = {
  typ?: InputMaybe<KontoTyp>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryKundeArgs = {
  id: Scalars['ID'];
};


export type QueryKundenauftraegeArgs = {
  kundeId: Scalars['ID'];
};


export type QueryKundenkontakteArgs = {
  kundeId: Scalars['ID'];
};


export type QueryLeistungArgs = {
  id: Scalars['ID'];
};


export type QueryLeistungenByMemberIdAndStatusAndLeistungsArtPagedArgs = {
  leistungsArt?: InputMaybe<LeistungsArt>;
  memberId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryLeistungenPagedArgs = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLohnArgs = {
  id: Scalars['ID'];
};


export type QueryMeArgs = {
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberArgs = {
  id: Scalars['ID'];
  year?: InputMaybe<Scalars['Int']>;
};


export type QuerySpesenAuszahlungenByEmpfaengerAndStatusIsInArgs = {
  empfaenger: Scalars['ID'];
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryToepfeArgs = {
  statusIn?: InputMaybe<Array<InputMaybe<TopfStatus>>>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryTopfArgs = {
  id: Scalars['ID'];
};


export type QueryUebertraegeByKontoIdAndStatusPagedArgs = {
  memberId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryUebertraegePagedArgs = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUebertragArgs = {
  id: Scalars['ID'];
};


export type QueryUpdateBeteiligteArgs = {
  input?: InputMaybe<UpdateBeteiligteInput>;
};


export type QueryUpdateBeteiligungAnteileArgs = {
  input?: InputMaybe<UpdateBeteiligungAnteileInput>;
};


export type QueryUpdateBeteiligungBetragArgs = {
  input?: InputMaybe<UpdateBeteiligungBetragInput>;
};


export type QueryUpdateBeteiligungFixbetragStatusArgs = {
  input?: InputMaybe<UpdateBeteiligungFixbetragStatusInput>;
};


export type QueryUpdateTotalBetragArgs = {
  input?: InputMaybe<UpdateTotalBetragInput>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryVerbuchteLeistungenArgs = {
  memberId: Scalars['ID'];
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryWithBetragBruttoArgs = {
  baseline: BruttoNettoMwstBetragInput;
  betrag: Scalars['BigDecimal'];
};


export type QueryWithBetragMwstArgs = {
  baseline: BruttoNettoMwstBetragInput;
  betrag: Scalars['BigDecimal'];
};


export type QueryWithBetragNettoArgs = {
  baseline: BruttoNettoMwstBetragInput;
  betrag: Scalars['BigDecimal'];
};

export type Tax = {
  __typename?: 'Tax';
  accountId?: Maybe<Scalars['Long']>;
  code?: Maybe<Scalars['String']>;
  digit?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  endMonth?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  netTaxValue?: Maybe<Scalars['String']>;
  startMonth?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  taxSettlementType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type Topf = Identifiable & {
  __typename?: 'Topf';
  antragUrl?: Maybe<Scalars['String']>;
  beschreibung?: Maybe<Scalars['String']>;
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  id: Scalars['ID'];
  imageContentType?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  kontoId: Scalars['String'];
  kontostand: Kontostand;
  status: TopfStatus;
  stimmen?: Maybe<Array<Maybe<TopfStimme>>>;
  verantwortlicher: Scalars['String'];
  version: Scalars['Int'];
};

export type TopfCreateInput = {
  antragUrl?: InputMaybe<Scalars['String']>;
  beschreibung?: InputMaybe<Scalars['String']>;
  bezeichnung: Scalars['String'];
  eroeffnungsbetrag: Scalars['BigDecimal'];
  kostentraeger: Array<BeteiligungInput>;
  verantwortlicher: Scalars['String'];
};

export type TopfReferenceInput = {
  id: Scalars['ID'];
};

export enum TopfStatus {
  Abgelehnt = 'ABGELEHNT',
  Antrag = 'ANTRAG',
  Geschlossen = 'GESCHLOSSEN',
  Offen = 'OFFEN'
}

export type TopfStimme = {
  __typename?: 'TopfStimme';
  kommentar?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  memberId: Scalars['ID'];
  veto: Scalars['Boolean'];
};

export type TopfStimmeInput = {
  kommentar?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
  veto: Scalars['Boolean'];
};

export type TopfUpdateInput = {
  antragUrl?: InputMaybe<Scalars['String']>;
  beschreibung?: InputMaybe<Scalars['String']>;
  bezeichnung: Scalars['String'];
  id: Scalars['ID'];
  verantwortlicher: Scalars['String'];
};

export type Transaktion = {
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  id: Scalars['ID'];
  status: TransaktionStatus;
  version?: Maybe<Scalars['Int']>;
};

export enum TransaktionStatus {
  InBearbeitung = 'IN_BEARBEITUNG',
  Ok = 'OK',
  Storniert = 'STORNIERT',
  ZuVerbuchen = 'ZU_VERBUCHEN'
}

export type Uebertrag = Transaktion & {
  __typename?: 'Uebertrag';
  betrag: Scalars['BigDecimal'];
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  id: Scalars['ID'];
  nach: Array<Beteiligung>;
  status: TransaktionStatus;
  version: Scalars['Int'];
  von: Array<Beteiligung>;
};

export type UebertragCreateInput = {
  betrag: Scalars['BigDecimal'];
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  nach: Array<BeteiligungInput>;
  von: Array<BeteiligungInput>;
};

export type UebertragPage = {
  __typename?: 'UebertragPage';
  content: Array<Uebertrag>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type UebertragUpdateInput = {
  betrag: Scalars['BigDecimal'];
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  id: Scalars['ID'];
  nach: Array<BeteiligungInput>;
  version: Scalars['Int'];
  von: Array<BeteiligungInput>;
};

export type UpdateBenutzerInput = {
  adresse?: InputMaybe<Scalars['String']>;
  ahv?: InputMaybe<Scalars['String']>;
  arbeitspensum: Scalars['Int'];
  aufMandatSuche?: InputMaybe<Scalars['Boolean']>;
  bankverbindung?: InputMaybe<BankverbindungInput>;
  beschaeftigungsgrad?: InputMaybe<Scalars['Int']>;
  bvg?: InputMaybe<BvgInput>;
  bvgLastYear?: InputMaybe<BvgInput>;
  email: Scalars['String'];
  geburtstag?: InputMaybe<Scalars['String']>;
  geschlecht?: InputMaybe<Geschlecht>;
  hasGA: Scalars['Boolean'];
  hasVerpflegung: Scalars['Boolean'];
  id: Scalars['ID'];
  isBuchhalter: Scalars['Boolean'];
  isLohnAutomatischGenerieren: Scalars['Boolean'];
  isMember: Scalars['Boolean'];
  kinderzulagen?: InputMaybe<Scalars['BigDecimal']>;
  ktg?: InputMaybe<KtgInput>;
  linkedin?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  monatslohn?: InputMaybe<Scalars['BigDecimal']>;
  monatslohnAusnahmen?: InputMaybe<Array<InputMaybe<LohnausnahmenInput>>>;
  name: Scalars['String'];
  pauschalspesen?: InputMaybe<Scalars['BigDecimal']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  telegram?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  unbezahlteMonate: Array<Scalars['String']>;
  webpage?: InputMaybe<Scalars['String']>;
};

export type UpdateBeteiligteInput = {
  baseline: BeteiligungenBerechnenBaselineInput;
  beteiligte: Array<Scalars['ID']>;
};

export type UpdateBeteiligungAnteileInput = {
  anteile: Scalars['Int'];
  baseline: BeteiligungenBerechnenBaselineInput;
  kontoId: Scalars['ID'];
};

export type UpdateBeteiligungBetragInput = {
  baseline: BeteiligungenBerechnenBaselineInput;
  betrag: Scalars['BigDecimal'];
  kontoId: Scalars['ID'];
};

export type UpdateBeteiligungFixbetragStatusInput = {
  baseline: BeteiligungenBerechnenBaselineInput;
  fixbetragStatus: Scalars['Boolean'];
  kontoId: Scalars['ID'];
};

export type UpdateKundeInput = {
  bezeichnung: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  plz?: InputMaybe<Scalars['String']>;
  stadt?: InputMaybe<Scalars['String']>;
  strasse?: InputMaybe<Scalars['String']>;
};

export type UpdateLohndatenInput = {
  bonus: Scalars['BigDecimal'];
  bonusNetto: Scalars['BigDecimal'];
  bruttoloehne: Array<Scalars['BigDecimal']>;
  id: Scalars['ID'];
  kinderzulagen: Scalars['BigDecimal'];
  ktg: Scalars['BigDecimal'];
  sozialversicherungenAG: Scalars['BigDecimal'];
  version: Scalars['Int'];
};

export type UpdateTotalBetragInput = {
  baseline: BeteiligungenBerechnenBaselineInput;
  betrag: Scalars['BigDecimal'];
};

export type UpsertAusgabeInput = {
  belegId?: InputMaybe<Scalars['String']>;
  betraege: BruttoNettoMwstBetragInput;
  bezahler: Array<BeteiligungInput>;
  bezeichnung: Scalars['String'];
  datum: Scalars['String'];
  id: Scalars['ID'];
  kostentraeger: Array<BeteiligungInput>;
  notizen: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
  wiederholung: WiederholungsArt;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAccountant?: Maybe<Scalars['Boolean']>;
  isSuperadmin?: Maybe<Scalars['Boolean']>;
  lastname?: Maybe<Scalars['String']>;
  salutationType?: Maybe<Scalars['String']>;
};

export type VerbuchteLeistung = {
  __typename?: 'VerbuchteLeistung';
  budgetTopf: Scalars['BigDecimal'];
  id: Scalars['ID'];
  kundendaten?: Maybe<Kundendaten>;
  leistungen: Scalars['BigDecimal'];
  leistungsArt: LeistungsArt;
  rechnungen: Scalars['Int'];
  topf?: Maybe<Topf>;
  verbrauchtKunde: Scalars['BigDecimal'];
  verbrauchtTopf: Scalars['BigDecimal'];
  verfuegbarTopf: Scalars['BigDecimal'];
};

export type Verpflegung = {
  __typename?: 'Verpflegung';
  abendessen: Scalars['Int'];
  fruehstueck: Scalars['Int'];
  mittagessen: Scalars['Int'];
  schaltstelleBuero: Scalars['Int'];
};

export type VerpflegungInput = {
  abendessen: Scalars['Int'];
  fruehstueck: Scalars['Int'];
  mittagessen: Scalars['Int'];
  schaltstelleBuero: Scalars['Int'];
};

export enum WiederholungsArt {
  Einmalig = 'EINMALIG',
  Monatlich = 'MONATLICH'
}

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'Account', id: string, name?: string | null, accountNo?: string | null }> };

export type AusgabeTemplateQueryVariables = Exact<{
  belegId?: InputMaybe<Scalars['ID']>;
}>;


export type AusgabeTemplateQuery = { __typename?: 'Query', ausgabeTemplate: { __typename?: 'Ausgabe', id: string, version?: string | null, datum: string, bezeichnung: string, notizen: string, belegId?: string | null, belegUrl?: string | null, belegContentType?: string | null, status: TransaktionStatus, wiederholung: WiederholungsArt, parentId?: string | null, betraege: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string }, kostentraeger: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, bezahler: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type AusgabeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AusgabeQuery = { __typename?: 'Query', ausgabe: { __typename?: 'Ausgabe', id: string, version?: string | null, datum: string, bezeichnung: string, notizen: string, belegId?: string | null, belegUrl?: string | null, belegContentType?: string | null, status: TransaktionStatus, wiederholung: WiederholungsArt, parentId?: string | null, betraege: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string }, kostentraeger: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, bezahler: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type AusgabenByKontoIdAndStatusPagedQueryVariables = Exact<{
  kostentraeger: Scalars['ID'];
  kostentraegerExakt?: InputMaybe<Scalars['Boolean']>;
  bezahler: Scalars['ID'];
  bezahlerExakt?: InputMaybe<Scalars['Boolean']>;
  statusIn: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  year: Scalars['Int'];
  verpflegungsSpesenAusLeistungenEinblenden: Scalars['Boolean'];
  page: Scalars['Int'];
  size: Scalars['Int'];
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type AusgabenByKontoIdAndStatusPagedQuery = { __typename?: 'Query', ausgabenByKontoIdAndStatusPaged: { __typename?: 'AusgabePage', totalPages: string, totalElements: string, content: Array<{ __typename?: 'Ausgabe', id: string, datum: string, bezeichnung: string, notizen: string, status: TransaktionStatus, wiederholung: WiederholungsArt, parentId?: string | null, betraege: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragNetto: string, betragMwst: string }, kostentraeger: Array<{ __typename?: 'Beteiligung', betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, bezahler: Array<{ __typename?: 'Beteiligung', betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> }> } };

export type AusgabeSpeichernMutationVariables = Exact<{
  input: UpsertAusgabeInput;
}>;


export type AusgabeSpeichernMutation = { __typename?: 'Mutation', ausgabeSpeichern: { __typename?: 'Ausgabe', id: string, version?: string | null, datum: string, bezeichnung: string, notizen: string, belegId?: string | null, belegUrl?: string | null, belegContentType?: string | null, status: TransaktionStatus, wiederholung: WiederholungsArt, parentId?: string | null, betraege: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string }, kostentraeger: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, bezahler: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type VerbuchenMutationVariables = Exact<{
  input: UpsertAusgabeInput;
}>;


export type VerbuchenMutation = { __typename?: 'Mutation', ausgabeVerbuchen: { __typename?: 'Ausgabe', id: string, version?: string | null, datum: string, bezeichnung: string, notizen: string, belegId?: string | null, belegUrl?: string | null, belegContentType?: string | null, status: TransaktionStatus, wiederholung: WiederholungsArt, parentId?: string | null, betraege: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string }, kostentraeger: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, bezahler: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type ZurVerbuchungFreigebenMutationVariables = Exact<{
  input: UpsertAusgabeInput;
}>;


export type ZurVerbuchungFreigebenMutation = { __typename?: 'Mutation', ausgabeZurVerbuchungFreigeben: { __typename?: 'Ausgabe', id: string, version?: string | null, datum: string, bezeichnung: string, notizen: string, belegId?: string | null, belegUrl?: string | null, belegContentType?: string | null, status: TransaktionStatus, wiederholung: WiederholungsArt, parentId?: string | null, betraege: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string }, kostentraeger: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, bezahler: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type AusgabeStornierenMutationVariables = Exact<{
  input: AusgabeStornierenInput;
}>;


export type AusgabeStornierenMutation = { __typename?: 'Mutation', ausgabeStornieren: { __typename?: 'Ausgabe', id: string, version?: string | null, datum: string, bezeichnung: string, notizen: string, belegId?: string | null, belegUrl?: string | null, belegContentType?: string | null, status: TransaktionStatus, wiederholung: WiederholungsArt, parentId?: string | null, betraege: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string }, kostentraeger: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, bezahler: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type AusgabeFieldsFragment = { __typename?: 'Ausgabe', id: string, version?: string | null, datum: string, bezeichnung: string, notizen: string, belegId?: string | null, belegUrl?: string | null, belegContentType?: string | null, status: TransaktionStatus, wiederholung: WiederholungsArt, parentId?: string | null, betraege: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string }, kostentraeger: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, bezahler: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> };

export type AuszahlungQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AuszahlungQuery = { __typename?: 'Query', auszahlung: { __typename?: 'Auszahlung', id: string, betrag: string, bezeichnung: string, datum: string, status: TransaktionStatus, version: string } };

export type SpesenAuszahlungenByEmpfaengerAndStatusIsInQueryVariables = Exact<{
  empfaenger: Scalars['ID'];
  statusIn: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type SpesenAuszahlungenByEmpfaengerAndStatusIsInQuery = { __typename?: 'Query', spesenAuszahlungenByEmpfaengerAndStatusIsIn: Array<{ __typename?: 'Auszahlung', id: string, betrag: string, bezeichnung: string, datum: string, status: TransaktionStatus, version: string } | null> };

export type BonusAuszahlungenByEmpfaengerAndStatusIsInQueryVariables = Exact<{
  empfaenger: Scalars['ID'];
  statusIn: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type BonusAuszahlungenByEmpfaengerAndStatusIsInQuery = { __typename?: 'Query', bonusAuszahlungenByEmpfaengerAndStatusIsIn: Array<{ __typename?: 'Auszahlung', id: string, betrag: string, bezeichnung: string, datum: string, status: TransaktionStatus, version: string } | null> };

export type UpsertAuszahlungMutationVariables = Exact<{
  input: AuszahlungInput;
}>;


export type UpsertAuszahlungMutation = { __typename?: 'Mutation', upsertAuszahlung: { __typename?: 'Auszahlung', id: string, betrag: string, bezeichnung: string, datum: string, status: TransaktionStatus, version: string } };

export type AuszahlungStornierenMutationVariables = Exact<{
  input: AuszahlungStornierenInput;
}>;


export type AuszahlungStornierenMutation = { __typename?: 'Mutation', auszahlungStornieren: { __typename?: 'Auszahlung', id: string } };

export type AuszahlungFieldsFragment = { __typename?: 'Auszahlung', id: string, betrag: string, bezeichnung: string, datum: string, status: TransaktionStatus, version: string };

export type MeQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'Benutzer', id: string, name: string, email: string, photoUrl?: string | null, isMember: boolean, isBuchhalter: boolean, adresse?: string | null, aufMandatSuche?: boolean | null, hasGA: boolean, hasVerpflegung: boolean, pauschalspesen?: string | null, geburtstag?: string | null, geschlecht?: Geschlecht | null, kinderzulagen?: string | null, ahv?: string | null, mobile?: string | null, telegram?: string | null, linkedin?: string | null, twitter?: string | null, webpage?: string | null, beschaeftigungsgrad?: string | null, monatslohn?: string | null, arbeitspensum: string, unbezahlteMonate: Array<string>, isLohnAutomatischGenerieren: boolean, member?: { __typename?: 'Member', id: string } | null, bvg?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, bvgLastYear?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, ktg?: { __typename?: 'Ktg', tage: string } | null, bankverbindung?: { __typename?: 'Bankverbindung', bic: string, iban: string, name: string } | null, monatslohnAusnahmen?: Array<{ __typename?: 'Lohnausnahmen', monatslohn: string, yearMonth: string } | null> | null } };

export type BenutzerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BenutzerQuery = { __typename?: 'Query', benutzer: { __typename?: 'Benutzer', id: string, name: string, email: string, photoUrl?: string | null, isMember: boolean, isBuchhalter: boolean, adresse?: string | null, aufMandatSuche?: boolean | null, hasGA: boolean, hasVerpflegung: boolean, pauschalspesen?: string | null, geburtstag?: string | null, geschlecht?: Geschlecht | null, kinderzulagen?: string | null, ahv?: string | null, mobile?: string | null, telegram?: string | null, linkedin?: string | null, twitter?: string | null, webpage?: string | null, beschaeftigungsgrad?: string | null, monatslohn?: string | null, arbeitspensum: string, unbezahlteMonate: Array<string>, isLohnAutomatischGenerieren: boolean, member?: { __typename?: 'Member', id: string } | null, bvg?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, bvgLastYear?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, ktg?: { __typename?: 'Ktg', tage: string } | null, bankverbindung?: { __typename?: 'Bankverbindung', bic: string, iban: string, name: string } | null, monatslohnAusnahmen?: Array<{ __typename?: 'Lohnausnahmen', monatslohn: string, yearMonth: string } | null> | null } };

export type BenutzerByMemberIdQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type BenutzerByMemberIdQuery = { __typename?: 'Query', member: { __typename?: 'Member', id: string, benutzer: { __typename?: 'Benutzer', id: string, name: string, email: string, photoUrl?: string | null, isMember: boolean, isBuchhalter: boolean, adresse?: string | null, aufMandatSuche?: boolean | null, hasGA: boolean, hasVerpflegung: boolean, pauschalspesen?: string | null, geburtstag?: string | null, geschlecht?: Geschlecht | null, kinderzulagen?: string | null, ahv?: string | null, mobile?: string | null, telegram?: string | null, linkedin?: string | null, twitter?: string | null, webpage?: string | null, beschaeftigungsgrad?: string | null, monatslohn?: string | null, arbeitspensum: string, unbezahlteMonate: Array<string>, isLohnAutomatischGenerieren: boolean, member?: { __typename?: 'Member', id: string } | null, bvg?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, bvgLastYear?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, ktg?: { __typename?: 'Ktg', tage: string } | null, bankverbindung?: { __typename?: 'Bankverbindung', bic: string, iban: string, name: string } | null, monatslohnAusnahmen?: Array<{ __typename?: 'Lohnausnahmen', monatslohn: string, yearMonth: string } | null> | null } } };

export type UpdateBenutzerMutationVariables = Exact<{
  input?: InputMaybe<UpdateBenutzerInput>;
}>;


export type UpdateBenutzerMutation = { __typename?: 'Mutation', updateBenutzer: { __typename?: 'Benutzer', id: string, name: string, email: string, photoUrl?: string | null, isMember: boolean, isBuchhalter: boolean, adresse?: string | null, aufMandatSuche?: boolean | null, hasGA: boolean, hasVerpflegung: boolean, pauschalspesen?: string | null, geburtstag?: string | null, geschlecht?: Geschlecht | null, kinderzulagen?: string | null, ahv?: string | null, mobile?: string | null, telegram?: string | null, linkedin?: string | null, twitter?: string | null, webpage?: string | null, beschaeftigungsgrad?: string | null, monatslohn?: string | null, arbeitspensum: string, unbezahlteMonate: Array<string>, isLohnAutomatischGenerieren: boolean, member?: { __typename?: 'Member', id: string } | null, bvg?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, bvgLastYear?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, ktg?: { __typename?: 'Ktg', tage: string } | null, bankverbindung?: { __typename?: 'Bankverbindung', bic: string, iban: string, name: string } | null, monatslohnAusnahmen?: Array<{ __typename?: 'Lohnausnahmen', monatslohn: string, yearMonth: string } | null> | null } };

export type BenutzerFieldsFragment = { __typename?: 'Benutzer', id: string, name: string, email: string, photoUrl?: string | null, isMember: boolean, isBuchhalter: boolean, adresse?: string | null, aufMandatSuche?: boolean | null, hasGA: boolean, hasVerpflegung: boolean, pauschalspesen?: string | null, geburtstag?: string | null, geschlecht?: Geschlecht | null, kinderzulagen?: string | null, ahv?: string | null, mobile?: string | null, telegram?: string | null, linkedin?: string | null, twitter?: string | null, webpage?: string | null, beschaeftigungsgrad?: string | null, monatslohn?: string | null, arbeitspensum: string, unbezahlteMonate: Array<string>, isLohnAutomatischGenerieren: boolean, member?: { __typename?: 'Member', id: string } | null, bvg?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, bvgLastYear?: { __typename?: 'Bvg', arbeitgeberAnteil: string, arbeitnehmerAnteil: string } | null, ktg?: { __typename?: 'Ktg', tage: string } | null, bankverbindung?: { __typename?: 'Bankverbindung', bic: string, iban: string, name: string } | null, monatslohnAusnahmen?: Array<{ __typename?: 'Lohnausnahmen', monatslohn: string, yearMonth: string } | null> | null };

export type UpdateBeteiligteQueryVariables = Exact<{
  input?: InputMaybe<UpdateBeteiligteInput>;
}>;


export type UpdateBeteiligteQuery = { __typename?: 'Query', updateBeteiligte: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> };

export type UpdateBeteiligungAnteileQueryVariables = Exact<{
  input?: InputMaybe<UpdateBeteiligungAnteileInput>;
}>;


export type UpdateBeteiligungAnteileQuery = { __typename?: 'Query', updateBeteiligungAnteile: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> };

export type UpdateBeteiligungBetragQueryVariables = Exact<{
  input?: InputMaybe<UpdateBeteiligungBetragInput>;
}>;


export type UpdateBeteiligungBetragQuery = { __typename?: 'Query', updateBeteiligungBetrag: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> };

export type UpdateBeteiligungFixbetragStatusQueryVariables = Exact<{
  input?: InputMaybe<UpdateBeteiligungFixbetragStatusInput>;
}>;


export type UpdateBeteiligungFixbetragStatusQuery = { __typename?: 'Query', updateBeteiligungFixbetragStatus: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> };

export type UpdateTotalBetragQueryVariables = Exact<{
  input?: InputMaybe<UpdateTotalBetragInput>;
}>;


export type UpdateTotalBetragQuery = { __typename?: 'Query', updateTotalBetrag: Array<{ __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> };

export type BeteiligungFieldsFragment = { __typename?: 'Beteiligung', kontoId: string, anteile?: string | null, fixbetrag: boolean, betrag: string, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } };

export type CreateBillMutationVariables = Exact<{
  input?: InputMaybe<CreateBill>;
}>;


export type CreateBillMutation = { __typename?: 'Mutation', createBill: string };

export type ErfolgsrechnungenQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type ErfolgsrechnungenQuery = { __typename?: 'Query', erfolgsrechnungen: Array<{ __typename?: 'Erfolgsrechnung', memberId: string, memberName: string, umsatzExtern: string, umsatzIntern: string, umsatzUebertraege: string, kostenUmsatzabgabe: string, kostenAusgaben: string, kostenTotalabzuegeAg: string, kostenTotalabzuegeAn: string, kostenNettolohn: string, kostenUebertraege: string, spesenTotal: string, totalUmsatz: string, totalKosten: string, totalSaldo: string }> };

export type ErfolgsrechnungByMemberIdAndYearQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type ErfolgsrechnungByMemberIdAndYearQuery = { __typename?: 'Query', erfolgsrechnungByMemberIdAndYear: { __typename?: 'Erfolgsrechnung', memberId: string, memberName: string, umsatzExtern: string, umsatzIntern: string, umsatzUebertraege: string, kostenUmsatzabgabe: string, kostenAusgaben: string, kostenTotalabzuegeAg: string, kostenTotalabzuegeAn: string, kostenNettolohn: string, kostenUebertraege: string, spesenTotal: string, totalUmsatz: string, totalKosten: string, totalSaldo: string } };

export type BonusByMemberIdAndYearQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type BonusByMemberIdAndYearQuery = { __typename?: 'Query', bonusByMemberIdAndYear: string };

export type ErfolgsrechnungDetailByMemberIdAndYearQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type ErfolgsrechnungDetailByMemberIdAndYearQuery = { __typename?: 'Query', erfolgsrechnungDetailByMemberIdAndYear: { __typename?: 'ErfolgsrechnungDetailSheet', data: Array<{ __typename?: 'ErfolgsrechnungDetailEntry', key: string, entries: Array<{ __typename?: 'ErfolgsrechnungDetail', valuta: string, betrag: string, bezeichnung: string } | null> } | null> } };

export type ErfolgsrechnungFieldsFragment = { __typename?: 'Erfolgsrechnung', memberId: string, memberName: string, umsatzExtern: string, umsatzIntern: string, umsatzUebertraege: string, kostenUmsatzabgabe: string, kostenAusgaben: string, kostenTotalabzuegeAg: string, kostenTotalabzuegeAn: string, kostenNettolohn: string, kostenUebertraege: string, spesenTotal: string, totalUmsatz: string, totalKosten: string, totalSaldo: string };

export type KontosByTypeQueryVariables = Exact<{
  type: KontoTyp;
}>;


export type KontosByTypeQuery = { __typename?: 'Query', konten: Array<{ __typename?: 'Konto', id: string, version: string, bezeichnung: string, typ: KontoTyp, iconUrl?: string | null, besitzer: { __typename: 'Member' } | { __typename: 'Topf', datum: string, status: TopfStatus } }> };

export type KontosByTypeAndYearQueryVariables = Exact<{
  type: KontoTyp;
  year: Scalars['Int'];
}>;


export type KontosByTypeAndYearQuery = { __typename?: 'Query', kontosByTypeAndYear: Array<{ __typename?: 'Konto', id: string, version: string, bezeichnung: string, typ: KontoTyp, iconUrl?: string | null, besitzer: { __typename: 'Member' } | { __typename: 'Topf', datum: string, status: TopfStatus } }> };

export type KontoFieldsFragment = { __typename?: 'Konto', id: string, version: string, bezeichnung: string, typ: KontoTyp, iconUrl?: string | null, besitzer: { __typename: 'Member' } | { __typename: 'Topf', datum: string, status: TopfStatus } };

export type KontoMinimalFieldsFragment = { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null };

export type KontostandFieldsFragment = { __typename?: 'Kontostand', budget: string, verbraucht: string, verfuegbar: string, verfuegbarProzent: string, geplant: string };

export type BuchungFieldsFragment = { __typename?: 'Buchung', id: string, valuta: string, bezeichnung: string, betrag: string, sourceTransaktion?: { __typename: 'Ausgabe', id: string } | { __typename: 'Auszahlung', id: string } | { __typename: 'Leistung', id: string } | { __typename: 'Uebertrag', id: string } | null, opposingKonten: Array<{ __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null }> };

type TransaktionReferenceFields_Ausgabe_Fragment = { __typename: 'Ausgabe', id: string };

type TransaktionReferenceFields_Auszahlung_Fragment = { __typename: 'Auszahlung', id: string };

type TransaktionReferenceFields_Leistung_Fragment = { __typename: 'Leistung', id: string };

type TransaktionReferenceFields_Uebertrag_Fragment = { __typename: 'Uebertrag', id: string };

export type TransaktionReferenceFieldsFragment = TransaktionReferenceFields_Ausgabe_Fragment | TransaktionReferenceFields_Auszahlung_Fragment | TransaktionReferenceFields_Leistung_Fragment | TransaktionReferenceFields_Uebertrag_Fragment;

export type BuchungenPagedQueryVariables = Exact<{
  kontoId: Scalars['ID'];
  year?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type BuchungenPagedQuery = { __typename?: 'Query', buchungenPaged: { __typename?: 'BuchungPage', totalElements: string, totalPages: string, content: Array<{ __typename?: 'Buchung', id: string, valuta: string, bezeichnung: string, betrag: string, sourceTransaktion?: { __typename: 'Ausgabe', id: string } | { __typename: 'Auszahlung', id: string } | { __typename: 'Leistung', id: string } | { __typename: 'Uebertrag', id: string } | null, opposingKonten: Array<{ __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null }> }> } };

export type KundeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KundeQuery = { __typename?: 'Query', kunde: { __typename?: 'Kunde', id: string, bezeichnung: string, strasse?: string | null, plz?: string | null, stadt?: string | null, email?: string | null, type?: string | null } };

export type AllKundenQueryVariables = Exact<{ [key: string]: never; }>;


export type AllKundenQuery = { __typename?: 'Query', kunden: Array<{ __typename?: 'Kunde', id: string, bezeichnung: string, strasse?: string | null, plz?: string | null, stadt?: string | null, email?: string | null, type?: string | null }> };

export type KundenauftraegeQueryVariables = Exact<{
  kundeId: Scalars['ID'];
}>;


export type KundenauftraegeQuery = { __typename?: 'Query', kundenauftraege: Array<{ __typename?: 'Auftrag', id: string, bezeichnung: string, positionen: Array<{ __typename?: 'AuftragsPosition', id: string, bezeichnung: string, preisProEinheit: string }> }> };

export type KundenkontakteQueryVariables = Exact<{
  kundeId: Scalars['ID'];
}>;


export type KundenkontakteQuery = { __typename?: 'Query', kundenkontakte: Array<{ __typename?: 'Kontakt', id: string, bezeichnung: string }> };

export type CreateKundeMutationVariables = Exact<{
  input?: InputMaybe<CreateKundeInput>;
}>;


export type CreateKundeMutation = { __typename?: 'Mutation', createKunde: string };

export type UpdateKundeMutationVariables = Exact<{
  input?: InputMaybe<UpdateKundeInput>;
}>;


export type UpdateKundeMutation = { __typename?: 'Mutation', updateKunde: string };

export type LeistungQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LeistungQuery = { __typename?: 'Query', leistung: { __typename?: 'Leistung', belegUrl?: string | null, belegContentType?: string | null, id: string, version: string, datum: string, status: TransaktionStatus, bexioStatus: LeistungsBexioStatus, bezeichnung: string, notizen: string, erbringerId: string, topfId?: string | null, leistungsArt?: LeistungsArt | null, rechnungUrl?: string | null, belegId?: string | null, arbeitsleistungen: Array<{ __typename?: 'Arbeitsleistung', bezeichnung?: string | null, anzahlStunden: string, stundensatz: string, betrag: string, auftragsPositionId?: string | null }>, verpflegung: { __typename?: 'Verpflegung', fruehstueck: string, mittagessen: string, abendessen: string, schaltstelleBuero: string }, kundendaten?: { __typename?: 'Kundendaten', kundeId?: string | null, kontaktId?: string | null, auftragId?: string | null } | null } };

export type LeistungenPagedQueryQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type LeistungenPagedQueryQuery = { __typename?: 'Query', leistungenPaged: { __typename?: 'LeistungPage', totalPages: string, totalElements: string, content: Array<{ __typename?: 'Leistung', id: string, datum: string, bezeichnung: string, betragTotal: string, leistungsArt?: LeistungsArt | null, status: TransaktionStatus, bexioStatus: LeistungsBexioStatus, rechnungUrl?: string | null, erbringer: { __typename?: 'Member', name: string, photoUrl?: string | null }, kundendaten?: { __typename?: 'Kundendaten', kunde?: { __typename?: 'Kunde', bezeichnung: string } | null } | null, topf?: { __typename?: 'Topf', bezeichnung: string, status: TopfStatus } | null }> } };

export type LeistungenByMemberIdAndStatusAndLeistungsArtPagedQueryVariables = Exact<{
  memberId: Scalars['ID'];
  statusIn: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  leistungsArt?: InputMaybe<LeistungsArt>;
  year: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type LeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery = { __typename?: 'Query', leistungenByMemberIdAndStatusAndLeistungsArtPaged: { __typename?: 'LeistungPage', totalPages: string, totalElements: string, content: Array<{ __typename?: 'Leistung', id: string, datum: string, bezeichnung: string, betragTotal: string, leistungsArt?: LeistungsArt | null, status: TransaktionStatus, bexioStatus: LeistungsBexioStatus, rechnungUrl?: string | null, erbringer: { __typename?: 'Member', name: string, photoUrl?: string | null }, kundendaten?: { __typename?: 'Kundendaten', kunde?: { __typename?: 'Kunde', bezeichnung: string } | null } | null, topf?: { __typename?: 'Topf', bezeichnung: string, status: TopfStatus } | null }> } };

export type VerbuchteLeistungenQueryVariables = Exact<{
  memberId: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type VerbuchteLeistungenQuery = { __typename?: 'Query', verbuchteLeistungen?: Array<{ __typename?: 'VerbuchteLeistung', id: string, leistungen: string, leistungsArt: LeistungsArt, rechnungen: string, verbrauchtKunde: string, budgetTopf: string, verbrauchtTopf: string, verfuegbarTopf: string, topf?: { __typename?: 'Topf', bezeichnung: string } | null, kundendaten?: { __typename?: 'Kundendaten', kunde?: { __typename?: 'Kunde', bezeichnung: string } | null, auftrag?: { __typename?: 'Auftrag', bezeichnung: string, budget: string } | null } | null }> | null };

export type UpsertLeistungMutationVariables = Exact<{
  input: LeistungInput;
}>;


export type UpsertLeistungMutation = { __typename?: 'Mutation', upsertLeistung: { __typename?: 'Leistung', id: string } };

export type LeistungFreigebenMutationVariables = Exact<{
  input: LeistungInput;
}>;


export type LeistungFreigebenMutation = { __typename?: 'Mutation', leistungZurVerbuchungFreigeben: { __typename?: 'Leistung', id: string } };

export type LeistungVerbuchenMutationVariables = Exact<{
  input: LeistungInput;
}>;


export type LeistungVerbuchenMutation = { __typename?: 'Mutation', leistungVerbuchen: { __typename?: 'Leistung', id: string } };

export type LeistungStornierenMutationVariables = Exact<{
  input: LeistungStornierenInput;
}>;


export type LeistungStornierenMutation = { __typename?: 'Mutation', leistungStornieren: { __typename?: 'Leistung', id: string } };

export type LeistungFieldsFragment = { __typename?: 'Leistung', id: string, version: string, datum: string, status: TransaktionStatus, bexioStatus: LeistungsBexioStatus, bezeichnung: string, notizen: string, erbringerId: string, topfId?: string | null, leistungsArt?: LeistungsArt | null, rechnungUrl?: string | null, belegId?: string | null, arbeitsleistungen: Array<{ __typename?: 'Arbeitsleistung', bezeichnung?: string | null, anzahlStunden: string, stundensatz: string, betrag: string, auftragsPositionId?: string | null }>, verpflegung: { __typename?: 'Verpflegung', fruehstueck: string, mittagessen: string, abendessen: string, schaltstelleBuero: string }, kundendaten?: { __typename?: 'Kundendaten', kundeId?: string | null, kontaktId?: string | null, auftragId?: string | null } | null };

export type ErbringerFieldsFragment = { __typename?: 'Konto', id: string, bezeichnung: string, iconUrl?: string | null };

export type LeistungenPagedContentFragment = { __typename?: 'Leistung', id: string, datum: string, bezeichnung: string, betragTotal: string, leistungsArt?: LeistungsArt | null, status: TransaktionStatus, bexioStatus: LeistungsBexioStatus, rechnungUrl?: string | null, erbringer: { __typename?: 'Member', name: string, photoUrl?: string | null }, kundendaten?: { __typename?: 'Kundendaten', kunde?: { __typename?: 'Kunde', bezeichnung: string } | null } | null, topf?: { __typename?: 'Topf', bezeichnung: string, status: TopfStatus } | null };

export type LohnQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LohnQuery = { __typename?: 'Query', lohn: { __typename?: 'Lohn', id: string, ahvIvEoAg: string, ahvIvEoAgFormel: string, ahvIvEoAn: string, ahvIvEoAnFormel: string, ahvVerwaltungAg: string, ahvVerwaltungAgFormel: string, alvAg: string, alvAgFormel: string, alvAn: string, alvAnFormel: string, alv2Ag: string, alv2AgFormel: string, alv2An: string, alv2AnFormel: string, bvgAg: string, bvgAn: string, brutto: string, buAg: string, buAgFormel: string, nbuAn: string, nbuAnFormel: string, fakAg: string, fakAgFormel: string, ktgAg: string, ktgAn: string, ktgTyp: string, kinderzulagen: string, netto: string, nettoUngerundet: string, totalAbzuegeAg: string, totalAbzuegeAn: string, yearMonth: string, auszahlbar: string } };

export type LoehneByMemberIdQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type LoehneByMemberIdQuery = { __typename?: 'Query', member: { __typename?: 'Member', id: string, loehne: Array<{ __typename?: 'Lohn', id: string, ahvIvEoAg: string, ahvIvEoAgFormel: string, ahvIvEoAn: string, ahvIvEoAnFormel: string, ahvVerwaltungAg: string, ahvVerwaltungAgFormel: string, alvAg: string, alvAgFormel: string, alvAn: string, alvAnFormel: string, alv2Ag: string, alv2AgFormel: string, alv2An: string, alv2AnFormel: string, bvgAg: string, bvgAn: string, brutto: string, buAg: string, buAgFormel: string, nbuAn: string, nbuAnFormel: string, fakAg: string, fakAgFormel: string, ktgAg: string, ktgAn: string, ktgTyp: string, kinderzulagen: string, netto: string, nettoUngerundet: string, totalAbzuegeAg: string, totalAbzuegeAn: string, yearMonth: string, auszahlbar: string }> } };

export type LohnausweisByMemberIdQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type LohnausweisByMemberIdQuery = { __typename?: 'Query', member: { __typename?: 'Member', id: string, lohnausweis: { __typename?: 'Lohnausweis', brutto: string, kinderzulagen: string, bvgAn: string, bvgAg: string, netto: string, sozialAbgabenAn: string, sozialAbgabenAg: string, spesen: string, pauschalspesen: string } } };

export type DeleteLohnMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLohnMutation = { __typename?: 'Mutation', deleteLohn: string };

export type DeleteLoehneMutationVariables = Exact<{
  yearMonth?: InputMaybe<Scalars['String']>;
  benutzerId?: InputMaybe<Scalars['String']>;
}>;


export type DeleteLoehneMutation = { __typename?: 'Mutation', deleteLoehne: Array<string> };

export type LohnFieldsFragment = { __typename?: 'Lohn', id: string, ahvIvEoAg: string, ahvIvEoAgFormel: string, ahvIvEoAn: string, ahvIvEoAnFormel: string, ahvVerwaltungAg: string, ahvVerwaltungAgFormel: string, alvAg: string, alvAgFormel: string, alvAn: string, alvAnFormel: string, alv2Ag: string, alv2AgFormel: string, alv2An: string, alv2AnFormel: string, bvgAg: string, bvgAn: string, brutto: string, buAg: string, buAgFormel: string, nbuAn: string, nbuAnFormel: string, fakAg: string, fakAgFormel: string, ktgAg: string, ktgAn: string, ktgTyp: string, kinderzulagen: string, netto: string, nettoUngerundet: string, totalAbzuegeAg: string, totalAbzuegeAn: string, yearMonth: string, auszahlbar: string };

export type MembersQueryVariables = Exact<{ [key: string]: never; }>;


export type MembersQuery = { __typename?: 'Query', members: Array<{ __typename?: 'Member', id: string, name: string, photoUrl?: string | null }> };

export type MembersSpesenQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type MembersSpesenQuery = { __typename?: 'Query', members: Array<{ __typename?: 'Member', id: string, name: string, photoUrl?: string | null, spesen: { __typename?: 'AuszahlungsDaten', ausbezahlt: string, betrag: string, verbleibend: string }, spesenAuszahlungen: Array<{ __typename?: 'Auszahlung', betrag: string, bezeichnung: string, datum: string, id: string, status: TransaktionStatus, version: string }> }> };

export type MembersBonusQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type MembersBonusQuery = { __typename?: 'Query', members: Array<{ __typename?: 'Member', id: string, name: string, photoUrl?: string | null, lohndaten: { __typename?: 'Lohndaten', bonus: string, bonusNetto: string }, bonus: { __typename?: 'AuszahlungsDaten', ausbezahlt: string, betrag: string, verbleibend: string }, bonusAuszahlungen: Array<{ __typename?: 'Auszahlung', betrag: string, bezeichnung: string, datum: string, id: string, status: TransaktionStatus, version: string }> }> };

export type MemberKontenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MemberKontenQuery = { __typename?: 'Query', member: { __typename?: 'Member', id: string, guthabenkonto: { __typename?: 'Konto', id: string }, spesenkonto: { __typename?: 'Konto', id: string } } };

export type MemberDashboardQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type MemberDashboardQuery = { __typename?: 'Query', member: { __typename?: 'Member', id: string, name: string, photoUrl?: string | null, guthabenkonto: { __typename?: 'Konto', id: string, kontostand: { __typename?: 'Kontostand', budget: string, verbraucht: string, verfuegbar: string, verfuegbarProzent: string, geplant: string }, letzteBuchungen: Array<{ __typename?: 'Buchung', id: string, valuta: string, bezeichnung: string, betrag: string, sourceTransaktion?: { __typename: 'Ausgabe', id: string } | { __typename: 'Auszahlung', id: string } | { __typename: 'Leistung', id: string } | { __typename: 'Uebertrag', id: string } | null, opposingKonten: Array<{ __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null }> }> }, spesenkonto: { __typename?: 'Konto', id: string, kontostand: { __typename?: 'Kontostand', budget: string, verbraucht: string, verfuegbar: string, verfuegbarProzent: string, geplant: string }, letzteBuchungen: Array<{ __typename?: 'Buchung', id: string, valuta: string, bezeichnung: string, betrag: string, sourceTransaktion?: { __typename: 'Ausgabe', id: string } | { __typename: 'Auszahlung', id: string } | { __typename: 'Leistung', id: string } | { __typename: 'Uebertrag', id: string } | null, opposingKonten: Array<{ __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null }> }> }, bonus: { __typename?: 'AuszahlungsDaten', ausbezahlt: string, betrag: string, verbleibend: string } } };

export type MembersLohndatenQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type MembersLohndatenQuery = { __typename?: 'Query', members: Array<{ __typename?: 'Member', id: string, name: string, photoUrl?: string | null, lohndaten: { __typename?: 'Lohndaten', id: string, memberId: string, jahr: string, bruttoloehne: Array<string>, sozialversicherungenAG: string, ktg: string, bonus: string, bonusNetto: string, kinderzulagen: string, version: string } }> };

export type UpdateLohndatenMutationVariables = Exact<{
  updateInfo: Array<UpdateLohndatenInput> | UpdateLohndatenInput;
}>;


export type UpdateLohndatenMutation = { __typename?: 'Mutation', updateLohndaten: Array<{ __typename?: 'Lohndaten', id: string, memberId: string, jahr: string, bruttoloehne: Array<string>, sozialversicherungenAG: string, ktg: string, bonus: string, bonusNetto: string, kinderzulagen: string, version: string }> };

export type MemberFieldsFragment = { __typename?: 'Member', id: string, name: string, photoUrl?: string | null };

export type LohndatenFieldsFragment = { __typename?: 'Lohndaten', id: string, memberId: string, jahr: string, bruttoloehne: Array<string>, sozialversicherungenAG: string, ktg: string, bonus: string, bonusNetto: string, kinderzulagen: string, version: string };

export type WithBetragBruttoQueryVariables = Exact<{
  betrag: Scalars['BigDecimal'];
  baseline: BruttoNettoMwstBetragInput;
}>;


export type WithBetragBruttoQuery = { __typename?: 'Query', withBetragBrutto: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string } };

export type WithBetragMwstQueryVariables = Exact<{
  betrag: Scalars['BigDecimal'];
  baseline: BruttoNettoMwstBetragInput;
}>;


export type WithBetragMwstQuery = { __typename?: 'Query', withBetragMwst: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string } };

export type WithBetragNettoQueryVariables = Exact<{
  betrag: Scalars['BigDecimal'];
  baseline: BruttoNettoMwstBetragInput;
}>;


export type WithBetragNettoQuery = { __typename?: 'Query', withBetragNetto: { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string } };

export type BruttoNettoMwstBetragFieldsFragment = { __typename?: 'BruttoNettoMwstBetrag', betragBrutto: string, betragMwst: string, betragNetto: string };

export type TaxesQueryVariables = Exact<{ [key: string]: never; }>;


export type TaxesQuery = { __typename?: 'Query', taxes: Array<{ __typename?: 'Tax', id: string, code?: string | null, displayName?: string | null }> };

export type ToepfeQueryVariables = Exact<{
  statusIn?: InputMaybe<Array<InputMaybe<TopfStatus>> | InputMaybe<TopfStatus>>;
  year?: InputMaybe<Scalars['Int']>;
}>;


export type ToepfeQuery = { __typename?: 'Query', toepfe: Array<{ __typename?: 'Topf', id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, kontostand: { __typename?: 'Kontostand', budget: string, verbraucht: string, verfuegbar: string, verfuegbarProzent: string, geplant: string }, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null }> };

export type TopfQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TopfQuery = { __typename?: 'Query', topf: { __typename?: 'Topf', kontoId: string, id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, kontostand: { __typename?: 'Kontostand', budget: string, verbraucht: string, verfuegbar: string, verfuegbarProzent: string, geplant: string }, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null } };

export type CreateTopfMutationVariables = Exact<{
  updateInfo: TopfCreateInput;
}>;


export type CreateTopfMutation = { __typename?: 'Mutation', createTopf: { __typename?: 'Topf', id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null } };

export type TopfFieldsFragment = { __typename?: 'Topf', id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null };

export type TopfDetailsFragment = { __typename?: 'Topf', kontoId: string, id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, kontostand: { __typename?: 'Kontostand', budget: string, verbraucht: string, verfuegbar: string, verfuegbarProzent: string, geplant: string }, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null };

export type TopfSchliessenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TopfSchliessenMutation = { __typename?: 'Mutation', topfSchliessen: { __typename?: 'Topf', id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null } };

export type TopfOeffnenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TopfOeffnenMutation = { __typename?: 'Mutation', topfOeffnen: { __typename?: 'Topf', id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null } };

export type TopfStimmeAbgebenMutationVariables = Exact<{
  id: Scalars['ID'];
  stimme?: InputMaybe<TopfStimmeInput>;
}>;


export type TopfStimmeAbgebenMutation = { __typename?: 'Mutation', topfStimmeAbgeben: { __typename?: 'Topf', id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null } };

export type DeleteTopfMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTopfMutation = { __typename?: 'Mutation', deleteTopf: string };

export type UpdateTopfMutationVariables = Exact<{
  updateInfo: TopfUpdateInput;
}>;


export type UpdateTopfMutation = { __typename?: 'Mutation', updateTopf: { __typename?: 'Topf', id: string, version: string, bezeichnung: string, verantwortlicher: string, beschreibung?: string | null, antragUrl?: string | null, status: TopfStatus, imageUrl?: string | null, datum: string, stimmen?: Array<{ __typename?: 'TopfStimme', veto: boolean, member?: { __typename?: 'Member', name: string } | null } | null> | null } };

export type UebertragQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UebertragQuery = { __typename?: 'Query', uebertrag?: { __typename?: 'Uebertrag', id: string, version: string, datum: string, bezeichnung: string, betrag: string, status: TransaktionStatus, von: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, nach: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } | null };

export type UebertraegeByKontoIdAndStatusPagedQueryVariables = Exact<{
  memberId: Scalars['ID'];
  statusIn: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  year: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type UebertraegeByKontoIdAndStatusPagedQuery = { __typename?: 'Query', uebertraegeByKontoIdAndStatusPaged: { __typename?: 'UebertragPage', totalPages: string, totalElements: string, content: Array<{ __typename?: 'Uebertrag', id: string, version: string, datum: string, bezeichnung: string, betrag: string, status: TransaktionStatus, von: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, nach: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> }> } };

export type UebertraegePagedQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type UebertraegePagedQuery = { __typename?: 'Query', uebertraegePaged: { __typename?: 'UebertragPage', totalPages: string, totalElements: string, content: Array<{ __typename?: 'Uebertrag', id: string, version: string, datum: string, bezeichnung: string, betrag: string, status: TransaktionStatus, von: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, nach: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> }> } };

export type CreateUebertragMutationVariables = Exact<{
  input?: InputMaybe<UebertragCreateInput>;
}>;


export type CreateUebertragMutation = { __typename?: 'Mutation', createUebertrag: { __typename?: 'Uebertrag', id: string, version: string, datum: string, bezeichnung: string, betrag: string, status: TransaktionStatus, von: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, nach: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type StorniereUebertragMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StorniereUebertragMutation = { __typename?: 'Mutation', storniereUebertrag: { __typename?: 'Uebertrag', id: string, version: string, datum: string, bezeichnung: string, betrag: string, status: TransaktionStatus, von: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, nach: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type UpdateUebertragMutationVariables = Exact<{
  input?: InputMaybe<UebertragUpdateInput>;
}>;


export type UpdateUebertragMutation = { __typename?: 'Mutation', updateUebertrag: { __typename?: 'Uebertrag', id: string, version: string, datum: string, bezeichnung: string, betrag: string, status: TransaktionStatus, von: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, nach: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> } };

export type UebertragFieldsFragment = { __typename?: 'Uebertrag', id: string, version: string, datum: string, bezeichnung: string, betrag: string, status: TransaktionStatus, von: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }>, nach: Array<{ __typename?: 'Beteiligung', kontoId: string, betrag: string, anteile?: string | null, fixbetrag: boolean, konto: { __typename?: 'Konto', bezeichnung: string, iconUrl?: string | null } }> };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, firstname?: string | null, lastname?: string | null, email?: string | null, salutationType?: string | null, isAccountant?: boolean | null, isSuperadmin?: boolean | null } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, firstname?: string | null, lastname?: string | null, email?: string | null, salutationType?: string | null, isAccountant?: boolean | null, isSuperadmin?: boolean | null }> };

export const AusgabeFieldsFragmentDoc = gql`
    fragment ausgabeFields on Ausgabe {
  id
  version
  datum
  bezeichnung
  notizen
  betraege {
    betragBrutto
    betragMwst
    betragNetto
  }
  belegId
  belegUrl
  belegContentType
  kostentraeger {
    kontoId
    anteile
    fixbetrag
    betrag
    konto {
      bezeichnung
      iconUrl
    }
  }
  bezahler {
    kontoId
    anteile
    fixbetrag
    betrag
    konto {
      bezeichnung
      iconUrl
    }
  }
  status
  wiederholung
  parentId
}
    `;
export const AuszahlungFieldsFragmentDoc = gql`
    fragment auszahlungFields on Auszahlung {
  id
  betrag
  bezeichnung
  datum
  status
  version
}
    `;
export const BenutzerFieldsFragmentDoc = gql`
    fragment benutzerFields on Benutzer {
  id
  name
  email
  photoUrl
  isMember
  isBuchhalter
  member {
    id
  }
  adresse
  aufMandatSuche
  hasGA
  hasVerpflegung
  pauschalspesen
  geburtstag
  geschlecht
  kinderzulagen
  ahv
  mobile
  telegram
  linkedin
  twitter
  webpage
  bvg {
    arbeitgeberAnteil
    arbeitnehmerAnteil
  }
  bvgLastYear {
    arbeitgeberAnteil
    arbeitnehmerAnteil
  }
  ktg {
    tage
  }
  bankverbindung {
    bic
    iban
    name
  }
  monatslohnAusnahmen {
    monatslohn
    yearMonth
  }
  beschaeftigungsgrad
  monatslohn
  arbeitspensum
  unbezahlteMonate
  isLohnAutomatischGenerieren
}
    `;
export const BeteiligungFieldsFragmentDoc = gql`
    fragment beteiligungFields on Beteiligung {
  kontoId
  anteile
  fixbetrag
  betrag
  konto {
    bezeichnung
    iconUrl
  }
}
    `;
export const ErfolgsrechnungFieldsFragmentDoc = gql`
    fragment erfolgsrechnungFields on Erfolgsrechnung {
  memberId
  memberName
  umsatzExtern
  umsatzIntern
  umsatzUebertraege
  kostenUmsatzabgabe
  kostenAusgaben
  kostenTotalabzuegeAg
  kostenTotalabzuegeAn
  kostenNettolohn
  kostenUebertraege
  spesenTotal
  totalUmsatz
  totalKosten
  totalSaldo
}
    `;
export const KontoFieldsFragmentDoc = gql`
    fragment kontoFields on Konto {
  id
  version
  bezeichnung
  typ
  iconUrl
  besitzer {
    __typename
    ... on Topf {
      datum
      status
    }
  }
}
    `;
export const TransaktionReferenceFieldsFragmentDoc = gql`
    fragment transaktionReferenceFields on Transaktion {
  id
  __typename
}
    `;
export const KontoMinimalFieldsFragmentDoc = gql`
    fragment kontoMinimalFields on Konto {
  bezeichnung
  iconUrl
}
    `;
export const BuchungFieldsFragmentDoc = gql`
    fragment buchungFields on Buchung {
  id
  valuta
  bezeichnung
  betrag
  sourceTransaktion {
    ...transaktionReferenceFields
  }
  opposingKonten {
    ...kontoMinimalFields
  }
}
    ${TransaktionReferenceFieldsFragmentDoc}
${KontoMinimalFieldsFragmentDoc}`;
export const LeistungFieldsFragmentDoc = gql`
    fragment leistungFields on Leistung {
  id
  version
  datum
  status
  bexioStatus
  bezeichnung
  notizen
  erbringerId
  arbeitsleistungen {
    bezeichnung
    anzahlStunden
    stundensatz
    betrag
    auftragsPositionId
  }
  verpflegung {
    fruehstueck
    mittagessen
    abendessen
    schaltstelleBuero
  }
  kundendaten {
    kundeId
    kontaktId
    auftragId
  }
  topfId
  leistungsArt
  rechnungUrl
  belegId
}
    `;
export const ErbringerFieldsFragmentDoc = gql`
    fragment erbringerFields on Konto {
  id
  bezeichnung
  iconUrl
}
    `;
export const LeistungenPagedContentFragmentDoc = gql`
    fragment leistungenPagedContent on Leistung {
  id
  datum
  erbringer {
    name
    photoUrl
  }
  bezeichnung
  kundendaten {
    kunde {
      bezeichnung
    }
  }
  betragTotal
  topf {
    bezeichnung
    status
  }
  leistungsArt
  status
  bexioStatus
  rechnungUrl
}
    `;
export const LohnFieldsFragmentDoc = gql`
    fragment lohnFields on Lohn {
  id
  ahvIvEoAg
  ahvIvEoAgFormel
  ahvIvEoAn
  ahvIvEoAnFormel
  ahvVerwaltungAg
  ahvVerwaltungAgFormel
  alvAg
  alvAgFormel
  alvAn
  alvAnFormel
  alv2Ag
  alv2AgFormel
  alv2An
  alv2AnFormel
  bvgAg
  bvgAn
  brutto
  buAg
  buAgFormel
  nbuAn
  nbuAnFormel
  fakAg
  fakAgFormel
  ktgAg
  ktgAn
  ktgTyp
  kinderzulagen
  netto
  nettoUngerundet
  totalAbzuegeAg
  totalAbzuegeAn
  yearMonth
  kinderzulagen
  auszahlbar
}
    `;
export const MemberFieldsFragmentDoc = gql`
    fragment memberFields on Member {
  id
  name
  photoUrl
}
    `;
export const LohndatenFieldsFragmentDoc = gql`
    fragment lohndatenFields on Lohndaten {
  id
  memberId
  jahr
  bruttoloehne
  sozialversicherungenAG
  ktg
  bonus
  bonusNetto
  kinderzulagen
  version
}
    `;
export const BruttoNettoMwstBetragFieldsFragmentDoc = gql`
    fragment bruttoNettoMwstBetragFields on BruttoNettoMwstBetrag {
  betragBrutto
  betragMwst
  betragNetto
}
    `;
export const TopfFieldsFragmentDoc = gql`
    fragment topfFields on Topf {
  id
  version
  bezeichnung
  verantwortlicher
  beschreibung
  antragUrl
  status
  imageUrl
  datum
  stimmen {
    veto
    member {
      name
    }
  }
}
    `;
export const KontostandFieldsFragmentDoc = gql`
    fragment kontostandFields on Kontostand {
  budget
  verbraucht
  verfuegbar
  verfuegbarProzent
  geplant
}
    `;
export const TopfDetailsFragmentDoc = gql`
    fragment topfDetails on Topf {
  ...topfFields
  kontostand {
    ...kontostandFields
  }
  kontoId
}
    ${TopfFieldsFragmentDoc}
${KontostandFieldsFragmentDoc}`;
export const UebertragFieldsFragmentDoc = gql`
    fragment uebertragFields on Uebertrag {
  id
  version
  datum
  bezeichnung
  betrag
  von {
    kontoId
    konto {
      bezeichnung
      iconUrl
    }
    betrag
    anteile
    fixbetrag
  }
  nach {
    kontoId
    konto {
      bezeichnung
      iconUrl
    }
    betrag
    anteile
    fixbetrag
  }
  status
}
    `;
export const AccountsDocument = gql`
    query accounts {
  accounts {
    id
    name
    accountNo
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To use a Smart Query within a Vue component, call `useAccountsQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     accounts: useAccountsQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useAccountsQuery = createSmartQueryOptionsFunction<
  AccountsQuery,
  AccountsQueryVariables,
  ApolloError
>(AccountsDocument);

export const AusgabeTemplateDocument = gql`
    query ausgabeTemplate($belegId: ID) {
  ausgabeTemplate(belegId: $belegId) {
    ...ausgabeFields
  }
}
    ${AusgabeFieldsFragmentDoc}`;

/**
 * __useAusgabeTemplateQuery__
 *
 * To use a Smart Query within a Vue component, call `useAusgabeTemplateQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     ausgabeTemplate: useAusgabeTemplateQuery({
 *       variables: {
 *         belegId: // value for 'belegId'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useAusgabeTemplateQuery = createSmartQueryOptionsFunction<
  AusgabeTemplateQuery,
  AusgabeTemplateQueryVariables,
  ApolloError
>(AusgabeTemplateDocument);

export const AusgabeDocument = gql`
    query ausgabe($id: ID!) {
  ausgabe(id: $id) {
    ...ausgabeFields
  }
}
    ${AusgabeFieldsFragmentDoc}`;

/**
 * __useAusgabeQuery__
 *
 * To use a Smart Query within a Vue component, call `useAusgabeQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     ausgabe: useAusgabeQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useAusgabeQuery = createSmartQueryOptionsFunction<
  AusgabeQuery,
  AusgabeQueryVariables,
  ApolloError
>(AusgabeDocument);

export const AusgabenByKontoIdAndStatusPagedDocument = gql`
    query ausgabenByKontoIdAndStatusPaged($kostentraeger: ID!, $kostentraegerExakt: Boolean, $bezahler: ID!, $bezahlerExakt: Boolean, $statusIn: [String]!, $year: Int!, $verpflegungsSpesenAusLeistungenEinblenden: Boolean!, $page: Int!, $size: Int!, $sort: [String]) {
  ausgabenByKontoIdAndStatusPaged(
    kostentraeger: $kostentraeger
    kostentraegerExakt: $kostentraegerExakt
    bezahler: $bezahler
    bezahlerExakt: $bezahlerExakt
    statusIn: $statusIn
    year: $year
    verpflegungsSpesenAusLeistungenEinblenden: $verpflegungsSpesenAusLeistungenEinblenden
    page: $page
    size: $size
    sort: $sort
  ) {
    totalPages
    totalElements
    content {
      id
      datum
      bezeichnung
      notizen
      betraege {
        betragBrutto
        betragNetto
        betragMwst
      }
      kostentraeger {
        betrag
        konto {
          bezeichnung
          iconUrl
        }
      }
      bezahler {
        betrag
        konto {
          bezeichnung
          iconUrl
        }
      }
      status
      wiederholung
      parentId
    }
  }
}
    `;

/**
 * __useAusgabenByKontoIdAndStatusPagedQuery__
 *
 * To use a Smart Query within a Vue component, call `useAusgabenByKontoIdAndStatusPagedQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     ausgabenByKontoIdAndStatusPaged: useAusgabenByKontoIdAndStatusPagedQuery({
 *       variables: {
 *         kostentraeger: // value for 'kostentraeger'
 *         kostentraegerExakt: // value for 'kostentraegerExakt'
 *         bezahler: // value for 'bezahler'
 *         bezahlerExakt: // value for 'bezahlerExakt'
 *         statusIn: // value for 'statusIn'
 *         year: // value for 'year'
 *         verpflegungsSpesenAusLeistungenEinblenden: // value for 'verpflegungsSpesenAusLeistungenEinblenden'
 *         page: // value for 'page'
 *         size: // value for 'size'
 *         sort: // value for 'sort'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useAusgabenByKontoIdAndStatusPagedQuery = createSmartQueryOptionsFunction<
  AusgabenByKontoIdAndStatusPagedQuery,
  AusgabenByKontoIdAndStatusPagedQueryVariables,
  ApolloError
>(AusgabenByKontoIdAndStatusPagedDocument);

export const AusgabeSpeichernDocument = gql`
    mutation ausgabeSpeichern($input: UpsertAusgabeInput!) {
  ausgabeSpeichern(input: $input) {
    ...ausgabeFields
  }
}
    ${AusgabeFieldsFragmentDoc}`;

/**
 * __ausgabeSpeichernMutation__
 *
 * To run a mutation, you call `ausgabeSpeichernMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = ausgabeSpeichernMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const ausgabeSpeichernMutation = createMutationFunction<
  AusgabeSpeichernMutation,
  AusgabeSpeichernMutationVariables,
  ApolloError
>(AusgabeSpeichernDocument);

export const VerbuchenDocument = gql`
    mutation verbuchen($input: UpsertAusgabeInput!) {
  ausgabeVerbuchen(input: $input) {
    ...ausgabeFields
  }
}
    ${AusgabeFieldsFragmentDoc}`;

/**
 * __verbuchenMutation__
 *
 * To run a mutation, you call `verbuchenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = verbuchenMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const verbuchenMutation = createMutationFunction<
  VerbuchenMutation,
  VerbuchenMutationVariables,
  ApolloError
>(VerbuchenDocument);

export const ZurVerbuchungFreigebenDocument = gql`
    mutation zurVerbuchungFreigeben($input: UpsertAusgabeInput!) {
  ausgabeZurVerbuchungFreigeben(input: $input) {
    ...ausgabeFields
  }
}
    ${AusgabeFieldsFragmentDoc}`;

/**
 * __zurVerbuchungFreigebenMutation__
 *
 * To run a mutation, you call `zurVerbuchungFreigebenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = zurVerbuchungFreigebenMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const zurVerbuchungFreigebenMutation = createMutationFunction<
  ZurVerbuchungFreigebenMutation,
  ZurVerbuchungFreigebenMutationVariables,
  ApolloError
>(ZurVerbuchungFreigebenDocument);

export const AusgabeStornierenDocument = gql`
    mutation ausgabeStornieren($input: AusgabeStornierenInput!) {
  ausgabeStornieren(input: $input) {
    ...ausgabeFields
  }
}
    ${AusgabeFieldsFragmentDoc}`;

/**
 * __ausgabeStornierenMutation__
 *
 * To run a mutation, you call `ausgabeStornierenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = ausgabeStornierenMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const ausgabeStornierenMutation = createMutationFunction<
  AusgabeStornierenMutation,
  AusgabeStornierenMutationVariables,
  ApolloError
>(AusgabeStornierenDocument);

export const AuszahlungDocument = gql`
    query auszahlung($id: ID!) {
  auszahlung(id: $id) {
    ...auszahlungFields
  }
}
    ${AuszahlungFieldsFragmentDoc}`;

/**
 * __useAuszahlungQuery__
 *
 * To use a Smart Query within a Vue component, call `useAuszahlungQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     auszahlung: useAuszahlungQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useAuszahlungQuery = createSmartQueryOptionsFunction<
  AuszahlungQuery,
  AuszahlungQueryVariables,
  ApolloError
>(AuszahlungDocument);

export const SpesenAuszahlungenByEmpfaengerAndStatusIsInDocument = gql`
    query spesenAuszahlungenByEmpfaengerAndStatusIsIn($empfaenger: ID!, $statusIn: [String]!) {
  spesenAuszahlungenByEmpfaengerAndStatusIsIn(
    empfaenger: $empfaenger
    statusIn: $statusIn
  ) {
    ...auszahlungFields
  }
}
    ${AuszahlungFieldsFragmentDoc}`;

/**
 * __useSpesenAuszahlungenByEmpfaengerAndStatusIsInQuery__
 *
 * To use a Smart Query within a Vue component, call `useSpesenAuszahlungenByEmpfaengerAndStatusIsInQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     spesenAuszahlungenByEmpfaengerAndStatusIsIn: useSpesenAuszahlungenByEmpfaengerAndStatusIsInQuery({
 *       variables: {
 *         empfaenger: // value for 'empfaenger'
 *         statusIn: // value for 'statusIn'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useSpesenAuszahlungenByEmpfaengerAndStatusIsInQuery = createSmartQueryOptionsFunction<
  SpesenAuszahlungenByEmpfaengerAndStatusIsInQuery,
  SpesenAuszahlungenByEmpfaengerAndStatusIsInQueryVariables,
  ApolloError
>(SpesenAuszahlungenByEmpfaengerAndStatusIsInDocument);

export const BonusAuszahlungenByEmpfaengerAndStatusIsInDocument = gql`
    query bonusAuszahlungenByEmpfaengerAndStatusIsIn($empfaenger: ID!, $statusIn: [String]!) {
  bonusAuszahlungenByEmpfaengerAndStatusIsIn(
    empfaenger: $empfaenger
    statusIn: $statusIn
  ) {
    ...auszahlungFields
  }
}
    ${AuszahlungFieldsFragmentDoc}`;

/**
 * __useBonusAuszahlungenByEmpfaengerAndStatusIsInQuery__
 *
 * To use a Smart Query within a Vue component, call `useBonusAuszahlungenByEmpfaengerAndStatusIsInQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     bonusAuszahlungenByEmpfaengerAndStatusIsIn: useBonusAuszahlungenByEmpfaengerAndStatusIsInQuery({
 *       variables: {
 *         empfaenger: // value for 'empfaenger'
 *         statusIn: // value for 'statusIn'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useBonusAuszahlungenByEmpfaengerAndStatusIsInQuery = createSmartQueryOptionsFunction<
  BonusAuszahlungenByEmpfaengerAndStatusIsInQuery,
  BonusAuszahlungenByEmpfaengerAndStatusIsInQueryVariables,
  ApolloError
>(BonusAuszahlungenByEmpfaengerAndStatusIsInDocument);

export const UpsertAuszahlungDocument = gql`
    mutation upsertAuszahlung($input: AuszahlungInput!) {
  upsertAuszahlung(updateInfo: $input) {
    ...auszahlungFields
  }
}
    ${AuszahlungFieldsFragmentDoc}`;

/**
 * __upsertAuszahlungMutation__
 *
 * To run a mutation, you call `upsertAuszahlungMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = upsertAuszahlungMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const upsertAuszahlungMutation = createMutationFunction<
  UpsertAuszahlungMutation,
  UpsertAuszahlungMutationVariables,
  ApolloError
>(UpsertAuszahlungDocument);

export const AuszahlungStornierenDocument = gql`
    mutation auszahlungStornieren($input: AuszahlungStornierenInput!) {
  auszahlungStornieren(input: $input) {
    id
  }
}
    `;

/**
 * __auszahlungStornierenMutation__
 *
 * To run a mutation, you call `auszahlungStornierenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = auszahlungStornierenMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const auszahlungStornierenMutation = createMutationFunction<
  AuszahlungStornierenMutation,
  AuszahlungStornierenMutationVariables,
  ApolloError
>(AuszahlungStornierenDocument);

export const MeDocument = gql`
    query me($year: Int!) {
  me(year: $year) {
    ...benutzerFields
  }
}
    ${BenutzerFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To use a Smart Query within a Vue component, call `useMeQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     me: useMeQuery({
 *       variables: {
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useMeQuery = createSmartQueryOptionsFunction<
  MeQuery,
  MeQueryVariables,
  ApolloError
>(MeDocument);

export const BenutzerDocument = gql`
    query benutzer($id: ID!) {
  benutzer(id: $id) {
    ...benutzerFields
  }
}
    ${BenutzerFieldsFragmentDoc}`;

/**
 * __useBenutzerQuery__
 *
 * To use a Smart Query within a Vue component, call `useBenutzerQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     benutzer: useBenutzerQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useBenutzerQuery = createSmartQueryOptionsFunction<
  BenutzerQuery,
  BenutzerQueryVariables,
  ApolloError
>(BenutzerDocument);

export const BenutzerByMemberIdDocument = gql`
    query benutzerByMemberId($id: ID!, $year: Int!) {
  member(id: $id) {
    id
    benutzer(year: $year) {
      ...benutzerFields
    }
  }
}
    ${BenutzerFieldsFragmentDoc}`;

/**
 * __useBenutzerByMemberIdQuery__
 *
 * To use a Smart Query within a Vue component, call `useBenutzerByMemberIdQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     benutzerByMemberId: useBenutzerByMemberIdQuery({
 *       variables: {
 *         id: // value for 'id'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useBenutzerByMemberIdQuery = createSmartQueryOptionsFunction<
  BenutzerByMemberIdQuery,
  BenutzerByMemberIdQueryVariables,
  ApolloError
>(BenutzerByMemberIdDocument);

export const UpdateBenutzerDocument = gql`
    mutation updateBenutzer($input: UpdateBenutzerInput) {
  updateBenutzer(input: $input) {
    ...benutzerFields
  }
}
    ${BenutzerFieldsFragmentDoc}`;

/**
 * __updateBenutzerMutation__
 *
 * To run a mutation, you call `updateBenutzerMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = updateBenutzerMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const updateBenutzerMutation = createMutationFunction<
  UpdateBenutzerMutation,
  UpdateBenutzerMutationVariables,
  ApolloError
>(UpdateBenutzerDocument);

export const UpdateBeteiligteDocument = gql`
    query updateBeteiligte($input: UpdateBeteiligteInput) {
  updateBeteiligte(input: $input) {
    ...beteiligungFields
  }
}
    ${BeteiligungFieldsFragmentDoc}`;

/**
 * __useUpdateBeteiligteQuery__
 *
 * To use a Smart Query within a Vue component, call `useUpdateBeteiligteQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     updateBeteiligte: useUpdateBeteiligteQuery({
 *       variables: {
 *         input: // value for 'input'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUpdateBeteiligteQuery = createSmartQueryOptionsFunction<
  UpdateBeteiligteQuery,
  UpdateBeteiligteQueryVariables,
  ApolloError
>(UpdateBeteiligteDocument);

export const UpdateBeteiligungAnteileDocument = gql`
    query updateBeteiligungAnteile($input: UpdateBeteiligungAnteileInput) {
  updateBeteiligungAnteile(input: $input) {
    ...beteiligungFields
  }
}
    ${BeteiligungFieldsFragmentDoc}`;

/**
 * __useUpdateBeteiligungAnteileQuery__
 *
 * To use a Smart Query within a Vue component, call `useUpdateBeteiligungAnteileQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     updateBeteiligungAnteile: useUpdateBeteiligungAnteileQuery({
 *       variables: {
 *         input: // value for 'input'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUpdateBeteiligungAnteileQuery = createSmartQueryOptionsFunction<
  UpdateBeteiligungAnteileQuery,
  UpdateBeteiligungAnteileQueryVariables,
  ApolloError
>(UpdateBeteiligungAnteileDocument);

export const UpdateBeteiligungBetragDocument = gql`
    query updateBeteiligungBetrag($input: UpdateBeteiligungBetragInput) {
  updateBeteiligungBetrag(input: $input) {
    ...beteiligungFields
  }
}
    ${BeteiligungFieldsFragmentDoc}`;

/**
 * __useUpdateBeteiligungBetragQuery__
 *
 * To use a Smart Query within a Vue component, call `useUpdateBeteiligungBetragQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     updateBeteiligungBetrag: useUpdateBeteiligungBetragQuery({
 *       variables: {
 *         input: // value for 'input'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUpdateBeteiligungBetragQuery = createSmartQueryOptionsFunction<
  UpdateBeteiligungBetragQuery,
  UpdateBeteiligungBetragQueryVariables,
  ApolloError
>(UpdateBeteiligungBetragDocument);

export const UpdateBeteiligungFixbetragStatusDocument = gql`
    query updateBeteiligungFixbetragStatus($input: UpdateBeteiligungFixbetragStatusInput) {
  updateBeteiligungFixbetragStatus(input: $input) {
    ...beteiligungFields
  }
}
    ${BeteiligungFieldsFragmentDoc}`;

/**
 * __useUpdateBeteiligungFixbetragStatusQuery__
 *
 * To use a Smart Query within a Vue component, call `useUpdateBeteiligungFixbetragStatusQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     updateBeteiligungFixbetragStatus: useUpdateBeteiligungFixbetragStatusQuery({
 *       variables: {
 *         input: // value for 'input'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUpdateBeteiligungFixbetragStatusQuery = createSmartQueryOptionsFunction<
  UpdateBeteiligungFixbetragStatusQuery,
  UpdateBeteiligungFixbetragStatusQueryVariables,
  ApolloError
>(UpdateBeteiligungFixbetragStatusDocument);

export const UpdateTotalBetragDocument = gql`
    query updateTotalBetrag($input: UpdateTotalBetragInput) {
  updateTotalBetrag(input: $input) {
    ...beteiligungFields
  }
}
    ${BeteiligungFieldsFragmentDoc}`;

/**
 * __useUpdateTotalBetragQuery__
 *
 * To use a Smart Query within a Vue component, call `useUpdateTotalBetragQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     updateTotalBetrag: useUpdateTotalBetragQuery({
 *       variables: {
 *         input: // value for 'input'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUpdateTotalBetragQuery = createSmartQueryOptionsFunction<
  UpdateTotalBetragQuery,
  UpdateTotalBetragQueryVariables,
  ApolloError
>(UpdateTotalBetragDocument);

export const CreateBillDocument = gql`
    mutation createBill($input: CreateBill) {
  createBill(input: $input)
}
    `;

/**
 * __createBillMutation__
 *
 * To run a mutation, you call `createBillMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = createBillMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const createBillMutation = createMutationFunction<
  CreateBillMutation,
  CreateBillMutationVariables,
  ApolloError
>(CreateBillDocument);

export const ErfolgsrechnungenDocument = gql`
    query erfolgsrechnungen($year: Int!) {
  erfolgsrechnungen(year: $year) {
    ...erfolgsrechnungFields
  }
}
    ${ErfolgsrechnungFieldsFragmentDoc}`;

/**
 * __useErfolgsrechnungenQuery__
 *
 * To use a Smart Query within a Vue component, call `useErfolgsrechnungenQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     erfolgsrechnungen: useErfolgsrechnungenQuery({
 *       variables: {
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useErfolgsrechnungenQuery = createSmartQueryOptionsFunction<
  ErfolgsrechnungenQuery,
  ErfolgsrechnungenQueryVariables,
  ApolloError
>(ErfolgsrechnungenDocument);

export const ErfolgsrechnungByMemberIdAndYearDocument = gql`
    query erfolgsrechnungByMemberIdAndYear($id: ID!, $year: Int!) {
  erfolgsrechnungByMemberIdAndYear(memberId: $id, year: $year) {
    ...erfolgsrechnungFields
  }
}
    ${ErfolgsrechnungFieldsFragmentDoc}`;

/**
 * __useErfolgsrechnungByMemberIdAndYearQuery__
 *
 * To use a Smart Query within a Vue component, call `useErfolgsrechnungByMemberIdAndYearQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     erfolgsrechnungByMemberIdAndYear: useErfolgsrechnungByMemberIdAndYearQuery({
 *       variables: {
 *         id: // value for 'id'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useErfolgsrechnungByMemberIdAndYearQuery = createSmartQueryOptionsFunction<
  ErfolgsrechnungByMemberIdAndYearQuery,
  ErfolgsrechnungByMemberIdAndYearQueryVariables,
  ApolloError
>(ErfolgsrechnungByMemberIdAndYearDocument);

export const BonusByMemberIdAndYearDocument = gql`
    query bonusByMemberIdAndYear($id: ID!, $year: Int!) {
  bonusByMemberIdAndYear(memberId: $id, year: $year)
}
    `;

/**
 * __useBonusByMemberIdAndYearQuery__
 *
 * To use a Smart Query within a Vue component, call `useBonusByMemberIdAndYearQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     bonusByMemberIdAndYear: useBonusByMemberIdAndYearQuery({
 *       variables: {
 *         id: // value for 'id'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useBonusByMemberIdAndYearQuery = createSmartQueryOptionsFunction<
  BonusByMemberIdAndYearQuery,
  BonusByMemberIdAndYearQueryVariables,
  ApolloError
>(BonusByMemberIdAndYearDocument);

export const ErfolgsrechnungDetailByMemberIdAndYearDocument = gql`
    query erfolgsrechnungDetailByMemberIdAndYear($id: ID!, $year: Int!) {
  erfolgsrechnungDetailByMemberIdAndYear(memberId: $id, year: $year) {
    data {
      key
      entries {
        valuta
        betrag
        bezeichnung
      }
    }
  }
}
    `;

/**
 * __useErfolgsrechnungDetailByMemberIdAndYearQuery__
 *
 * To use a Smart Query within a Vue component, call `useErfolgsrechnungDetailByMemberIdAndYearQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     erfolgsrechnungDetailByMemberIdAndYear: useErfolgsrechnungDetailByMemberIdAndYearQuery({
 *       variables: {
 *         id: // value for 'id'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useErfolgsrechnungDetailByMemberIdAndYearQuery = createSmartQueryOptionsFunction<
  ErfolgsrechnungDetailByMemberIdAndYearQuery,
  ErfolgsrechnungDetailByMemberIdAndYearQueryVariables,
  ApolloError
>(ErfolgsrechnungDetailByMemberIdAndYearDocument);

export const KontosByTypeDocument = gql`
    query kontosByType($type: KontoTyp!) {
  konten(typ: $type) {
    ...kontoFields
  }
}
    ${KontoFieldsFragmentDoc}`;

/**
 * __useKontosByTypeQuery__
 *
 * To use a Smart Query within a Vue component, call `useKontosByTypeQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     kontosByType: useKontosByTypeQuery({
 *       variables: {
 *         type: // value for 'type'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useKontosByTypeQuery = createSmartQueryOptionsFunction<
  KontosByTypeQuery,
  KontosByTypeQueryVariables,
  ApolloError
>(KontosByTypeDocument);

export const KontosByTypeAndYearDocument = gql`
    query kontosByTypeAndYear($type: KontoTyp!, $year: Int!) {
  kontosByTypeAndYear(typ: $type, year: $year) {
    ...kontoFields
  }
}
    ${KontoFieldsFragmentDoc}`;

/**
 * __useKontosByTypeAndYearQuery__
 *
 * To use a Smart Query within a Vue component, call `useKontosByTypeAndYearQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     kontosByTypeAndYear: useKontosByTypeAndYearQuery({
 *       variables: {
 *         type: // value for 'type'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useKontosByTypeAndYearQuery = createSmartQueryOptionsFunction<
  KontosByTypeAndYearQuery,
  KontosByTypeAndYearQueryVariables,
  ApolloError
>(KontosByTypeAndYearDocument);

export const BuchungenPagedDocument = gql`
    query buchungenPaged($kontoId: ID!, $year: Int, $page: Int, $size: Int, $sort: [String]) {
  buchungenPaged(
    kontoId: $kontoId
    year: $year
    page: $page
    size: $size
    sort: $sort
  ) {
    content {
      ...buchungFields
    }
    totalElements
    totalPages
  }
}
    ${BuchungFieldsFragmentDoc}`;

/**
 * __useBuchungenPagedQuery__
 *
 * To use a Smart Query within a Vue component, call `useBuchungenPagedQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     buchungenPaged: useBuchungenPagedQuery({
 *       variables: {
 *         kontoId: // value for 'kontoId'
 *         year: // value for 'year'
 *         page: // value for 'page'
 *         size: // value for 'size'
 *         sort: // value for 'sort'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useBuchungenPagedQuery = createSmartQueryOptionsFunction<
  BuchungenPagedQuery,
  BuchungenPagedQueryVariables,
  ApolloError
>(BuchungenPagedDocument);

export const KundeDocument = gql`
    query kunde($id: ID!) {
  kunde(id: $id) {
    id
    bezeichnung
    strasse
    plz
    stadt
    email
    type
  }
}
    `;

/**
 * __useKundeQuery__
 *
 * To use a Smart Query within a Vue component, call `useKundeQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     kunde: useKundeQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useKundeQuery = createSmartQueryOptionsFunction<
  KundeQuery,
  KundeQueryVariables,
  ApolloError
>(KundeDocument);

export const AllKundenDocument = gql`
    query allKunden {
  kunden {
    id
    bezeichnung
    strasse
    plz
    stadt
    email
    type
  }
}
    `;

/**
 * __useAllKundenQuery__
 *
 * To use a Smart Query within a Vue component, call `useAllKundenQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     allKunden: useAllKundenQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useAllKundenQuery = createSmartQueryOptionsFunction<
  AllKundenQuery,
  AllKundenQueryVariables,
  ApolloError
>(AllKundenDocument);

export const KundenauftraegeDocument = gql`
    query kundenauftraege($kundeId: ID!) {
  kundenauftraege(kundeId: $kundeId) {
    id
    bezeichnung
    positionen {
      id
      bezeichnung
      preisProEinheit
    }
  }
}
    `;

/**
 * __useKundenauftraegeQuery__
 *
 * To use a Smart Query within a Vue component, call `useKundenauftraegeQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     kundenauftraege: useKundenauftraegeQuery({
 *       variables: {
 *         kundeId: // value for 'kundeId'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useKundenauftraegeQuery = createSmartQueryOptionsFunction<
  KundenauftraegeQuery,
  KundenauftraegeQueryVariables,
  ApolloError
>(KundenauftraegeDocument);

export const KundenkontakteDocument = gql`
    query kundenkontakte($kundeId: ID!) {
  kundenkontakte(kundeId: $kundeId) {
    id
    bezeichnung
  }
}
    `;

/**
 * __useKundenkontakteQuery__
 *
 * To use a Smart Query within a Vue component, call `useKundenkontakteQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     kundenkontakte: useKundenkontakteQuery({
 *       variables: {
 *         kundeId: // value for 'kundeId'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useKundenkontakteQuery = createSmartQueryOptionsFunction<
  KundenkontakteQuery,
  KundenkontakteQueryVariables,
  ApolloError
>(KundenkontakteDocument);

export const CreateKundeDocument = gql`
    mutation createKunde($input: CreateKundeInput) {
  createKunde(input: $input)
}
    `;

/**
 * __createKundeMutation__
 *
 * To run a mutation, you call `createKundeMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = createKundeMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const createKundeMutation = createMutationFunction<
  CreateKundeMutation,
  CreateKundeMutationVariables,
  ApolloError
>(CreateKundeDocument);

export const UpdateKundeDocument = gql`
    mutation updateKunde($input: UpdateKundeInput) {
  updateKunde(input: $input)
}
    `;

/**
 * __updateKundeMutation__
 *
 * To run a mutation, you call `updateKundeMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = updateKundeMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const updateKundeMutation = createMutationFunction<
  UpdateKundeMutation,
  UpdateKundeMutationVariables,
  ApolloError
>(UpdateKundeDocument);

export const LeistungDocument = gql`
    query leistung($id: ID!) {
  leistung(id: $id) {
    ...leistungFields
    belegUrl
    belegContentType
  }
}
    ${LeistungFieldsFragmentDoc}`;

/**
 * __useLeistungQuery__
 *
 * To use a Smart Query within a Vue component, call `useLeistungQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     leistung: useLeistungQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useLeistungQuery = createSmartQueryOptionsFunction<
  LeistungQuery,
  LeistungQueryVariables,
  ApolloError
>(LeistungDocument);

export const LeistungenPagedQueryDocument = gql`
    query leistungenPagedQuery($page: Int!, $size: Int!, $sort: [String]) {
  leistungenPaged(page: $page, size: $size, sort: $sort) {
    content {
      ...leistungenPagedContent
    }
    totalPages
    totalElements
  }
}
    ${LeistungenPagedContentFragmentDoc}`;

/**
 * __useLeistungenPagedQueryQuery__
 *
 * To use a Smart Query within a Vue component, call `useLeistungenPagedQueryQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     leistungenPagedQuery: useLeistungenPagedQueryQuery({
 *       variables: {
 *         page: // value for 'page'
 *         size: // value for 'size'
 *         sort: // value for 'sort'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useLeistungenPagedQueryQuery = createSmartQueryOptionsFunction<
  LeistungenPagedQueryQuery,
  LeistungenPagedQueryQueryVariables,
  ApolloError
>(LeistungenPagedQueryDocument);

export const LeistungenByMemberIdAndStatusAndLeistungsArtPagedDocument = gql`
    query leistungenByMemberIdAndStatusAndLeistungsArtPaged($memberId: ID!, $statusIn: [String]!, $leistungsArt: LeistungsArt, $year: Int!, $page: Int!, $size: Int!, $sort: [String]) {
  leistungenByMemberIdAndStatusAndLeistungsArtPaged(
    memberId: $memberId
    statusIn: $statusIn
    leistungsArt: $leistungsArt
    year: $year
    page: $page
    size: $size
    sort: $sort
  ) {
    content {
      ...leistungenPagedContent
    }
    totalPages
    totalElements
  }
}
    ${LeistungenPagedContentFragmentDoc}`;

/**
 * __useLeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery__
 *
 * To use a Smart Query within a Vue component, call `useLeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     leistungenByMemberIdAndStatusAndLeistungsArtPaged: useLeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery({
 *       variables: {
 *         memberId: // value for 'memberId'
 *         statusIn: // value for 'statusIn'
 *         leistungsArt: // value for 'leistungsArt'
 *         year: // value for 'year'
 *         page: // value for 'page'
 *         size: // value for 'size'
 *         sort: // value for 'sort'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useLeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery = createSmartQueryOptionsFunction<
  LeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery,
  LeistungenByMemberIdAndStatusAndLeistungsArtPagedQueryVariables,
  ApolloError
>(LeistungenByMemberIdAndStatusAndLeistungsArtPagedDocument);

export const VerbuchteLeistungenDocument = gql`
    query verbuchteLeistungen($memberId: ID!, $year: Int!) {
  verbuchteLeistungen(memberId: $memberId, year: $year) {
    id
    leistungen
    leistungsArt
    rechnungen
    topf {
      bezeichnung
    }
    kundendaten {
      kunde {
        bezeichnung
      }
      auftrag {
        bezeichnung
        budget
      }
    }
    verbrauchtKunde
    budgetTopf
    verbrauchtTopf
    verfuegbarTopf
  }
}
    `;

/**
 * __useVerbuchteLeistungenQuery__
 *
 * To use a Smart Query within a Vue component, call `useVerbuchteLeistungenQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     verbuchteLeistungen: useVerbuchteLeistungenQuery({
 *       variables: {
 *         memberId: // value for 'memberId'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useVerbuchteLeistungenQuery = createSmartQueryOptionsFunction<
  VerbuchteLeistungenQuery,
  VerbuchteLeistungenQueryVariables,
  ApolloError
>(VerbuchteLeistungenDocument);

export const UpsertLeistungDocument = gql`
    mutation upsertLeistung($input: LeistungInput!) {
  upsertLeistung(updateInfo: $input) {
    id
  }
}
    `;

/**
 * __upsertLeistungMutation__
 *
 * To run a mutation, you call `upsertLeistungMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = upsertLeistungMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const upsertLeistungMutation = createMutationFunction<
  UpsertLeistungMutation,
  UpsertLeistungMutationVariables,
  ApolloError
>(UpsertLeistungDocument);

export const LeistungFreigebenDocument = gql`
    mutation leistungFreigeben($input: LeistungInput!) {
  leistungZurVerbuchungFreigeben(updateInfo: $input) {
    id
  }
}
    `;

/**
 * __leistungFreigebenMutation__
 *
 * To run a mutation, you call `leistungFreigebenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = leistungFreigebenMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const leistungFreigebenMutation = createMutationFunction<
  LeistungFreigebenMutation,
  LeistungFreigebenMutationVariables,
  ApolloError
>(LeistungFreigebenDocument);

export const LeistungVerbuchenDocument = gql`
    mutation leistungVerbuchen($input: LeistungInput!) {
  leistungVerbuchen(input: $input) {
    id
  }
}
    `;

/**
 * __leistungVerbuchenMutation__
 *
 * To run a mutation, you call `leistungVerbuchenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = leistungVerbuchenMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const leistungVerbuchenMutation = createMutationFunction<
  LeistungVerbuchenMutation,
  LeistungVerbuchenMutationVariables,
  ApolloError
>(LeistungVerbuchenDocument);

export const LeistungStornierenDocument = gql`
    mutation leistungStornieren($input: LeistungStornierenInput!) {
  leistungStornieren(input: $input) {
    id
  }
}
    `;

/**
 * __leistungStornierenMutation__
 *
 * To run a mutation, you call `leistungStornierenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = leistungStornierenMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const leistungStornierenMutation = createMutationFunction<
  LeistungStornierenMutation,
  LeistungStornierenMutationVariables,
  ApolloError
>(LeistungStornierenDocument);

export const LohnDocument = gql`
    query lohn($id: ID!) {
  lohn(id: $id) {
    ...lohnFields
  }
}
    ${LohnFieldsFragmentDoc}`;

/**
 * __useLohnQuery__
 *
 * To use a Smart Query within a Vue component, call `useLohnQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     lohn: useLohnQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useLohnQuery = createSmartQueryOptionsFunction<
  LohnQuery,
  LohnQueryVariables,
  ApolloError
>(LohnDocument);

export const LoehneByMemberIdDocument = gql`
    query loehneByMemberId($id: ID!, $year: Int!) {
  member(id: $id) {
    id
    loehne(year: $year) {
      ...lohnFields
    }
  }
}
    ${LohnFieldsFragmentDoc}`;

/**
 * __useLoehneByMemberIdQuery__
 *
 * To use a Smart Query within a Vue component, call `useLoehneByMemberIdQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     loehneByMemberId: useLoehneByMemberIdQuery({
 *       variables: {
 *         id: // value for 'id'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useLoehneByMemberIdQuery = createSmartQueryOptionsFunction<
  LoehneByMemberIdQuery,
  LoehneByMemberIdQueryVariables,
  ApolloError
>(LoehneByMemberIdDocument);

export const LohnausweisByMemberIdDocument = gql`
    query lohnausweisByMemberId($id: ID!, $year: Int!) {
  member(id: $id) {
    id
    lohnausweis(year: $year) {
      brutto
      kinderzulagen
      bvgAn
      bvgAg
      netto
      sozialAbgabenAn
      sozialAbgabenAg
      spesen
      pauschalspesen
    }
  }
}
    `;

/**
 * __useLohnausweisByMemberIdQuery__
 *
 * To use a Smart Query within a Vue component, call `useLohnausweisByMemberIdQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     lohnausweisByMemberId: useLohnausweisByMemberIdQuery({
 *       variables: {
 *         id: // value for 'id'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useLohnausweisByMemberIdQuery = createSmartQueryOptionsFunction<
  LohnausweisByMemberIdQuery,
  LohnausweisByMemberIdQueryVariables,
  ApolloError
>(LohnausweisByMemberIdDocument);

export const DeleteLohnDocument = gql`
    mutation deleteLohn($id: ID!) {
  deleteLohn(id: $id)
}
    `;

/**
 * __deleteLohnMutation__
 *
 * To run a mutation, you call `deleteLohnMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = deleteLohnMutation(this, {
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export const deleteLohnMutation = createMutationFunction<
  DeleteLohnMutation,
  DeleteLohnMutationVariables,
  ApolloError
>(DeleteLohnDocument);

export const DeleteLoehneDocument = gql`
    mutation deleteLoehne($yearMonth: String, $benutzerId: String) {
  deleteLoehne(yearMonth: $yearMonth, benutzerId: $benutzerId)
}
    `;

/**
 * __deleteLoehneMutation__
 *
 * To run a mutation, you call `deleteLoehneMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = deleteLoehneMutation(this, {
 *   variables: {
 *     yearMonth: // value for 'yearMonth'
 *     benutzerId: // value for 'benutzerId'
 *   },
 * });
 */
export const deleteLoehneMutation = createMutationFunction<
  DeleteLoehneMutation,
  DeleteLoehneMutationVariables,
  ApolloError
>(DeleteLoehneDocument);

export const MembersDocument = gql`
    query members {
  members {
    ...memberFields
  }
}
    ${MemberFieldsFragmentDoc}`;

/**
 * __useMembersQuery__
 *
 * To use a Smart Query within a Vue component, call `useMembersQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     members: useMembersQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useMembersQuery = createSmartQueryOptionsFunction<
  MembersQuery,
  MembersQueryVariables,
  ApolloError
>(MembersDocument);

export const MembersSpesenDocument = gql`
    query membersSpesen($year: Int!) {
  members {
    id
    name
    photoUrl
    spesen(year: $year) {
      ausbezahlt
      betrag
      verbleibend
    }
    spesenAuszahlungen(year: $year) {
      betrag
      bezeichnung
      datum
      id
      status
      version
    }
  }
}
    `;

/**
 * __useMembersSpesenQuery__
 *
 * To use a Smart Query within a Vue component, call `useMembersSpesenQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     membersSpesen: useMembersSpesenQuery({
 *       variables: {
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useMembersSpesenQuery = createSmartQueryOptionsFunction<
  MembersSpesenQuery,
  MembersSpesenQueryVariables,
  ApolloError
>(MembersSpesenDocument);

export const MembersBonusDocument = gql`
    query membersBonus($year: Int!) {
  members {
    id
    name
    photoUrl
    lohndaten(year: $year) {
      bonus
      bonusNetto
    }
    bonus(year: $year) {
      ausbezahlt
      betrag
      verbleibend
    }
    bonusAuszahlungen(year: $year) {
      betrag
      bezeichnung
      datum
      id
      status
      version
    }
  }
}
    `;

/**
 * __useMembersBonusQuery__
 *
 * To use a Smart Query within a Vue component, call `useMembersBonusQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     membersBonus: useMembersBonusQuery({
 *       variables: {
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useMembersBonusQuery = createSmartQueryOptionsFunction<
  MembersBonusQuery,
  MembersBonusQueryVariables,
  ApolloError
>(MembersBonusDocument);

export const MemberKontenDocument = gql`
    query memberKonten($id: ID!) {
  member(id: $id) {
    id
    guthabenkonto {
      id
    }
    spesenkonto {
      id
    }
  }
}
    `;

/**
 * __useMemberKontenQuery__
 *
 * To use a Smart Query within a Vue component, call `useMemberKontenQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     memberKonten: useMemberKontenQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useMemberKontenQuery = createSmartQueryOptionsFunction<
  MemberKontenQuery,
  MemberKontenQueryVariables,
  ApolloError
>(MemberKontenDocument);

export const MemberDashboardDocument = gql`
    query memberDashboard($id: ID!, $year: Int!) {
  member(id: $id) {
    ...memberFields
    guthabenkonto {
      id
      kontostand(year: $year) {
        ...kontostandFields
      }
      letzteBuchungen(year: $year) {
        ...buchungFields
      }
    }
    spesenkonto {
      id
      kontostand(year: $year) {
        ...kontostandFields
      }
      letzteBuchungen(year: $year) {
        ...buchungFields
      }
    }
    bonus(year: $year) {
      ausbezahlt
      betrag
      verbleibend
    }
  }
}
    ${MemberFieldsFragmentDoc}
${KontostandFieldsFragmentDoc}
${BuchungFieldsFragmentDoc}`;

/**
 * __useMemberDashboardQuery__
 *
 * To use a Smart Query within a Vue component, call `useMemberDashboardQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     memberDashboard: useMemberDashboardQuery({
 *       variables: {
 *         id: // value for 'id'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useMemberDashboardQuery = createSmartQueryOptionsFunction<
  MemberDashboardQuery,
  MemberDashboardQueryVariables,
  ApolloError
>(MemberDashboardDocument);

export const MembersLohndatenDocument = gql`
    query membersLohndaten($year: Int!) {
  members {
    id
    name
    photoUrl
    lohndaten(year: $year) {
      ...lohndatenFields
    }
  }
}
    ${LohndatenFieldsFragmentDoc}`;

/**
 * __useMembersLohndatenQuery__
 *
 * To use a Smart Query within a Vue component, call `useMembersLohndatenQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     membersLohndaten: useMembersLohndatenQuery({
 *       variables: {
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useMembersLohndatenQuery = createSmartQueryOptionsFunction<
  MembersLohndatenQuery,
  MembersLohndatenQueryVariables,
  ApolloError
>(MembersLohndatenDocument);

export const UpdateLohndatenDocument = gql`
    mutation updateLohndaten($updateInfo: [UpdateLohndatenInput!]!) {
  updateLohndaten(input: $updateInfo) {
    ...lohndatenFields
  }
}
    ${LohndatenFieldsFragmentDoc}`;

/**
 * __updateLohndatenMutation__
 *
 * To run a mutation, you call `updateLohndatenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = updateLohndatenMutation(this, {
 *   variables: {
 *     updateInfo: // value for 'updateInfo'
 *   },
 * });
 */
export const updateLohndatenMutation = createMutationFunction<
  UpdateLohndatenMutation,
  UpdateLohndatenMutationVariables,
  ApolloError
>(UpdateLohndatenDocument);

export const WithBetragBruttoDocument = gql`
    query withBetragBrutto($betrag: BigDecimal!, $baseline: BruttoNettoMwstBetragInput!) {
  withBetragBrutto(betrag: $betrag, baseline: $baseline) {
    ...bruttoNettoMwstBetragFields
  }
}
    ${BruttoNettoMwstBetragFieldsFragmentDoc}`;

/**
 * __useWithBetragBruttoQuery__
 *
 * To use a Smart Query within a Vue component, call `useWithBetragBruttoQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     withBetragBrutto: useWithBetragBruttoQuery({
 *       variables: {
 *         betrag: // value for 'betrag'
 *         baseline: // value for 'baseline'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useWithBetragBruttoQuery = createSmartQueryOptionsFunction<
  WithBetragBruttoQuery,
  WithBetragBruttoQueryVariables,
  ApolloError
>(WithBetragBruttoDocument);

export const WithBetragMwstDocument = gql`
    query withBetragMwst($betrag: BigDecimal!, $baseline: BruttoNettoMwstBetragInput!) {
  withBetragMwst(betrag: $betrag, baseline: $baseline) {
    ...bruttoNettoMwstBetragFields
  }
}
    ${BruttoNettoMwstBetragFieldsFragmentDoc}`;

/**
 * __useWithBetragMwstQuery__
 *
 * To use a Smart Query within a Vue component, call `useWithBetragMwstQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     withBetragMwst: useWithBetragMwstQuery({
 *       variables: {
 *         betrag: // value for 'betrag'
 *         baseline: // value for 'baseline'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useWithBetragMwstQuery = createSmartQueryOptionsFunction<
  WithBetragMwstQuery,
  WithBetragMwstQueryVariables,
  ApolloError
>(WithBetragMwstDocument);

export const WithBetragNettoDocument = gql`
    query withBetragNetto($betrag: BigDecimal!, $baseline: BruttoNettoMwstBetragInput!) {
  withBetragNetto(betrag: $betrag, baseline: $baseline) {
    ...bruttoNettoMwstBetragFields
  }
}
    ${BruttoNettoMwstBetragFieldsFragmentDoc}`;

/**
 * __useWithBetragNettoQuery__
 *
 * To use a Smart Query within a Vue component, call `useWithBetragNettoQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     withBetragNetto: useWithBetragNettoQuery({
 *       variables: {
 *         betrag: // value for 'betrag'
 *         baseline: // value for 'baseline'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useWithBetragNettoQuery = createSmartQueryOptionsFunction<
  WithBetragNettoQuery,
  WithBetragNettoQueryVariables,
  ApolloError
>(WithBetragNettoDocument);

export const TaxesDocument = gql`
    query taxes {
  taxes {
    id
    code
    displayName
  }
}
    `;

/**
 * __useTaxesQuery__
 *
 * To use a Smart Query within a Vue component, call `useTaxesQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     taxes: useTaxesQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useTaxesQuery = createSmartQueryOptionsFunction<
  TaxesQuery,
  TaxesQueryVariables,
  ApolloError
>(TaxesDocument);

export const ToepfeDocument = gql`
    query toepfe($statusIn: [TopfStatus], $year: Int) {
  toepfe(statusIn: $statusIn, year: $year) {
    ...topfFields
    kontostand {
      ...kontostandFields
    }
  }
}
    ${TopfFieldsFragmentDoc}
${KontostandFieldsFragmentDoc}`;

/**
 * __useToepfeQuery__
 *
 * To use a Smart Query within a Vue component, call `useToepfeQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     toepfe: useToepfeQuery({
 *       variables: {
 *         statusIn: // value for 'statusIn'
 *         year: // value for 'year'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useToepfeQuery = createSmartQueryOptionsFunction<
  ToepfeQuery,
  ToepfeQueryVariables,
  ApolloError
>(ToepfeDocument);

export const TopfDocument = gql`
    query topf($id: ID!) {
  topf(id: $id) {
    ...topfDetails
  }
}
    ${TopfDetailsFragmentDoc}`;

/**
 * __useTopfQuery__
 *
 * To use a Smart Query within a Vue component, call `useTopfQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     topf: useTopfQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useTopfQuery = createSmartQueryOptionsFunction<
  TopfQuery,
  TopfQueryVariables,
  ApolloError
>(TopfDocument);

export const CreateTopfDocument = gql`
    mutation createTopf($updateInfo: TopfCreateInput!) {
  createTopf(input: $updateInfo) {
    ...topfFields
  }
}
    ${TopfFieldsFragmentDoc}`;

/**
 * __createTopfMutation__
 *
 * To run a mutation, you call `createTopfMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = createTopfMutation(this, {
 *   variables: {
 *     updateInfo: // value for 'updateInfo'
 *   },
 * });
 */
export const createTopfMutation = createMutationFunction<
  CreateTopfMutation,
  CreateTopfMutationVariables,
  ApolloError
>(CreateTopfDocument);

export const TopfSchliessenDocument = gql`
    mutation topfSchliessen($id: ID!) {
  topfSchliessen(id: $id) {
    ...topfFields
  }
}
    ${TopfFieldsFragmentDoc}`;

/**
 * __topfSchliessenMutation__
 *
 * To run a mutation, you call `topfSchliessenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = topfSchliessenMutation(this, {
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export const topfSchliessenMutation = createMutationFunction<
  TopfSchliessenMutation,
  TopfSchliessenMutationVariables,
  ApolloError
>(TopfSchliessenDocument);

export const TopfOeffnenDocument = gql`
    mutation topfOeffnen($id: ID!) {
  topfOeffnen(id: $id) {
    ...topfFields
  }
}
    ${TopfFieldsFragmentDoc}`;

/**
 * __topfOeffnenMutation__
 *
 * To run a mutation, you call `topfOeffnenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = topfOeffnenMutation(this, {
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export const topfOeffnenMutation = createMutationFunction<
  TopfOeffnenMutation,
  TopfOeffnenMutationVariables,
  ApolloError
>(TopfOeffnenDocument);

export const TopfStimmeAbgebenDocument = gql`
    mutation topfStimmeAbgeben($id: ID!, $stimme: TopfStimmeInput) {
  topfStimmeAbgeben(id: $id, stimme: $stimme) {
    ...topfFields
  }
}
    ${TopfFieldsFragmentDoc}`;

/**
 * __topfStimmeAbgebenMutation__
 *
 * To run a mutation, you call `topfStimmeAbgebenMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = topfStimmeAbgebenMutation(this, {
 *   variables: {
 *     id: // value for 'id'
 *     stimme: // value for 'stimme'
 *   },
 * });
 */
export const topfStimmeAbgebenMutation = createMutationFunction<
  TopfStimmeAbgebenMutation,
  TopfStimmeAbgebenMutationVariables,
  ApolloError
>(TopfStimmeAbgebenDocument);

export const DeleteTopfDocument = gql`
    mutation deleteTopf($id: ID!) {
  deleteTopf(id: $id)
}
    `;

/**
 * __deleteTopfMutation__
 *
 * To run a mutation, you call `deleteTopfMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = deleteTopfMutation(this, {
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export const deleteTopfMutation = createMutationFunction<
  DeleteTopfMutation,
  DeleteTopfMutationVariables,
  ApolloError
>(DeleteTopfDocument);

export const UpdateTopfDocument = gql`
    mutation updateTopf($updateInfo: TopfUpdateInput!) {
  updateTopf(input: $updateInfo) {
    ...topfFields
  }
}
    ${TopfFieldsFragmentDoc}`;

/**
 * __updateTopfMutation__
 *
 * To run a mutation, you call `updateTopfMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = updateTopfMutation(this, {
 *   variables: {
 *     updateInfo: // value for 'updateInfo'
 *   },
 * });
 */
export const updateTopfMutation = createMutationFunction<
  UpdateTopfMutation,
  UpdateTopfMutationVariables,
  ApolloError
>(UpdateTopfDocument);

export const UebertragDocument = gql`
    query uebertrag($id: ID!) {
  uebertrag(id: $id) {
    ...uebertragFields
  }
}
    ${UebertragFieldsFragmentDoc}`;

/**
 * __useUebertragQuery__
 *
 * To use a Smart Query within a Vue component, call `useUebertragQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     uebertrag: useUebertragQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUebertragQuery = createSmartQueryOptionsFunction<
  UebertragQuery,
  UebertragQueryVariables,
  ApolloError
>(UebertragDocument);

export const UebertraegeByKontoIdAndStatusPagedDocument = gql`
    query uebertraegeByKontoIdAndStatusPaged($memberId: ID!, $statusIn: [String]!, $year: Int!, $page: Int!, $size: Int!, $sort: [String]) {
  uebertraegeByKontoIdAndStatusPaged(
    memberId: $memberId
    statusIn: $statusIn
    year: $year
    page: $page
    size: $size
    sort: $sort
  ) {
    content {
      ...uebertragFields
    }
    totalPages
    totalElements
  }
}
    ${UebertragFieldsFragmentDoc}`;

/**
 * __useUebertraegeByKontoIdAndStatusPagedQuery__
 *
 * To use a Smart Query within a Vue component, call `useUebertraegeByKontoIdAndStatusPagedQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     uebertraegeByKontoIdAndStatusPaged: useUebertraegeByKontoIdAndStatusPagedQuery({
 *       variables: {
 *         memberId: // value for 'memberId'
 *         statusIn: // value for 'statusIn'
 *         year: // value for 'year'
 *         page: // value for 'page'
 *         size: // value for 'size'
 *         sort: // value for 'sort'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUebertraegeByKontoIdAndStatusPagedQuery = createSmartQueryOptionsFunction<
  UebertraegeByKontoIdAndStatusPagedQuery,
  UebertraegeByKontoIdAndStatusPagedQueryVariables,
  ApolloError
>(UebertraegeByKontoIdAndStatusPagedDocument);

export const UebertraegePagedDocument = gql`
    query uebertraegePaged($page: Int, $size: Int, $sort: [String]) {
  uebertraegePaged(page: $page, size: $size, sort: $sort) {
    totalPages
    totalElements
    content {
      ...uebertragFields
    }
  }
}
    ${UebertragFieldsFragmentDoc}`;

/**
 * __useUebertraegePagedQuery__
 *
 * To use a Smart Query within a Vue component, call `useUebertraegePagedQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     uebertraegePaged: useUebertraegePagedQuery({
 *       variables: {
 *         page: // value for 'page'
 *         size: // value for 'size'
 *         sort: // value for 'sort'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUebertraegePagedQuery = createSmartQueryOptionsFunction<
  UebertraegePagedQuery,
  UebertraegePagedQueryVariables,
  ApolloError
>(UebertraegePagedDocument);

export const CreateUebertragDocument = gql`
    mutation createUebertrag($input: UebertragCreateInput) {
  createUebertrag(input: $input) {
    ...uebertragFields
  }
}
    ${UebertragFieldsFragmentDoc}`;

/**
 * __createUebertragMutation__
 *
 * To run a mutation, you call `createUebertragMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = createUebertragMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const createUebertragMutation = createMutationFunction<
  CreateUebertragMutation,
  CreateUebertragMutationVariables,
  ApolloError
>(CreateUebertragDocument);

export const StorniereUebertragDocument = gql`
    mutation storniereUebertrag($id: ID!) {
  storniereUebertrag(id: $id) {
    ...uebertragFields
  }
}
    ${UebertragFieldsFragmentDoc}`;

/**
 * __storniereUebertragMutation__
 *
 * To run a mutation, you call `storniereUebertragMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = storniereUebertragMutation(this, {
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export const storniereUebertragMutation = createMutationFunction<
  StorniereUebertragMutation,
  StorniereUebertragMutationVariables,
  ApolloError
>(StorniereUebertragDocument);

export const UpdateUebertragDocument = gql`
    mutation updateUebertrag($input: UebertragUpdateInput) {
  updateUebertrag(input: $input) {
    ...uebertragFields
  }
}
    ${UebertragFieldsFragmentDoc}`;

/**
 * __updateUebertragMutation__
 *
 * To run a mutation, you call `updateUebertragMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = updateUebertragMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const updateUebertragMutation = createMutationFunction<
  UpdateUebertragMutation,
  UpdateUebertragMutationVariables,
  ApolloError
>(UpdateUebertragDocument);

export const UserDocument = gql`
    query user($id: ID!) {
  user(id: $id) {
    id
    firstname
    lastname
    email
    salutationType
    isAccountant
    isSuperadmin
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To use a Smart Query within a Vue component, call `useUserQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     user: useUserQuery({
 *       variables: {
 *         id: // value for 'id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUserQuery = createSmartQueryOptionsFunction<
  UserQuery,
  UserQueryVariables,
  ApolloError
>(UserDocument);

export const UsersDocument = gql`
    query users {
  users {
    id
    firstname
    lastname
    email
    salutationType
    isAccountant
    isSuperadmin
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To use a Smart Query within a Vue component, call `useUsersQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     users: useUsersQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUsersQuery = createSmartQueryOptionsFunction<
  UsersQuery,
  UsersQueryVariables,
  ApolloError
>(UsersDocument);
